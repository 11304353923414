import { createAsyncThunk } from '@reduxjs/toolkit'
import { minus } from 'number-precision'
import { BONUS_STATUS, BONUS_TYPES } from 'constants/index'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { activateWageringService, claimAccumulatedBonusService, claimBonusService, claimDayBonusService, claimReferralBonusService, forfeitBonusService, getAccumulatedBonusService, getAllBonusService, getAllRanksDetailsOfUserService, getReferralBonusService, getSplittedBonusService, getTotalRewardsService, getUserBonusService } from 'network/services/bonus.service'
import { setAccumulatedBonus, setReferralBonus, setSplittedBonus } from 'redux-thunk/redux/slices/bonus.slice'

export const getAllBonus = createAsyncThunk(
  'fetch/all-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getAllBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const activateWagering = createAsyncThunk(
  'activate-bonus/wagering',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await activateWageringService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const forfeitBonus = createAsyncThunk(
  'forfeit/bonus',
  async ({ bonusId, onSuccess }, thunkApi) => {
    try {
      const res = await forfeitBonusService({ bonusId })
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getUserBonus = createAsyncThunk(
  'fetch/user-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getUserBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimReferralBonus = createAsyncThunk(
  'claim/referral-bonus',
  async (data, thunkApi) => {
    const { referralBonus } = thunkApi?.getState()?.bonus
    try {
      const res = await claimReferralBonusService(data)
      thunkApi?.dispatch(setReferralBonus(referralBonus?.map(bonus =>
        (bonus?.bonusId === data?.bonusId)
          ? {
              ...bonus,
              status: res?.wageredUserBonus?.status,
              claimedAt: res?.wageredUserBonus?.claimedAt
            }
          : bonus)))
      openSuccessToaster({ message: res?.message })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getReferralBonus = createAsyncThunk(
  'fetch/referral-bonus',
  async (data, thunkApi) => {
    try {
      const res = await getReferralBonusService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimBonus = createAsyncThunk(
  'claim/bonus',
  async (data, thunkApi) => {
    try {
      const { splittedBonus, accumulatedBonus } = thunkApi?.getState()?.bonus
      const res = await claimBonusService(data)
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus?.groupedBonuses) : []
      if (splittedBonusKeys?.length > 0) {
        const updatedSplittedBonus = {}
        splittedBonusKeys?.forEach(key => {
          const updatedList = splittedBonus?.groupedBonuses[key]?.map(bonus =>
            (bonus?.id === res?.userBonus?.id)
              ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: (new Date())?.toString() }
              : bonus
          )
          updatedSplittedBonus[key] = updatedList
        })
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      thunkApi?.dispatch(setAccumulatedBonus(accumulatedBonus
        ?.map(bonus => {
          const updatedAmount = minus(+bonus?.bonusAmount, +res?.userBonus?.bonusAmount)
          return (bonus?.bonusType === data?.bonusType)
            ? {
                ...bonus,
                status: +updatedAmount > 0 ? BONUS_STATUS.READY_TO_CLAIM : BONUS_STATUS.CLAIMED,
                bonusAmount: updatedAmount,
                claimedAt: (new Date())?.toString()
              }
            : bonus
        }
        )))
      // thunkApi?.dispatch(setUserBonus(userBonus?.userbonusDetails?.map(bonus =>
      //   ((+bonus?.id) === (+res?.userBonus?.id)) ? { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: new Date() } : bonus)))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimAccumulatedBonus = createAsyncThunk(
  'claim-accumulated-bonus',
  async (data, thunkApi) => {
    try {
      const { accumulatedBonus, splittedBonus } = thunkApi?.getState()?.bonus
      const res = await claimAccumulatedBonusService(data)
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus?.groupedBonuses) : []
      if (splittedBonusKeys?.length > 0) {
        const updatedSplittedBonus = {}
        splittedBonusKeys?.forEach(key => {
          const updatedList = splittedBonus?.groupedBonuses[key]?.map(bonus => {
            const bonusType = (bonus?.bonusType === BONUS_TYPES.WEEKLYSPLITTED) ? BONUS_TYPES.WEEKLY : bonus?.bonusType
            if ((bonusType === data?.bonusType) && (bonus?.status === BONUS_STATUS.READY_TO_CLAIM)) {
              return { ...bonus, status: BONUS_STATUS.CLAIMED, claimedAt: (new Date())?.toString() }
            }
            return bonus
          }
          )
          updatedSplittedBonus[key] = updatedList
        })
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      thunkApi?.dispatch(setAccumulatedBonus(accumulatedBonus
        ?.map(bonus =>
          (bonus?.bonusType === data?.bonusType)
            ? { ...bonus, status: BONUS_STATUS.CLAIMED, bonusAmount: 0, claimedAt: (new Date())?.toString() }
            : bonus)))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const claimDayBonus = createAsyncThunk(
  'claim-day-bonus',
  async (data, thunkApi) => {
    try {
      const res = await claimDayBonusService(data)
      const { splittedBonus } = thunkApi?.getState()?.bonus
      const splittedBonusKeys = splittedBonus ? Object.keys(splittedBonus) : []
      if (splittedBonusKeys?.length > 0 && data?.dayKey >= 0) {
        const updatedSplittedBonus = {
          ...splittedBonus,
          [data?.dayKey]: splittedBonus[data?.dayKey]?.map(bonus => ({ ...bonus, status: BONUS_STATUS.CLAIMED }))
        }
        thunkApi?.dispatch(setSplittedBonus(updatedSplittedBonus))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getTotalRewards = createAsyncThunk(
  'total/reward',
  async (_, thunkApi) => {
    try {
      const res = await getTotalRewardsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAllRanksDetailsOfUser = createAsyncThunk(
  'all-ranks-details',
  async (_, thunkApi) => {
    try {
      const res = await getAllRanksDetailsOfUserService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getSplittedBonus = createAsyncThunk(
  'fetch-splitted-bonus-details',
  async (params, thunkApi) => {
    try {
      const res = await getSplittedBonusService(params)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getAccumulatedBonus = createAsyncThunk(
  'fetch-accumulated-bonus-details',
  async (_, thunkApi) => {
    try {
      const res = await getAccumulatedBonusService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
