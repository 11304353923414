import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCreditQueue, setSelectedWallet, setUserData } from 'redux-thunk/redux/slices/user.slice'
import useWalletSocketNameSpace from 'socket-resources/hooks/useWalletSocketNameSpace'
import CurrencyDropdown from '../CurrencyDropdown/index'
import { minus } from 'number-precision'

const WalletSubscription = (props) => {
  const { listenWalletUpdateEvent } = useWalletSocketNameSpace()
  const dispatch = useDispatch()
  const { user, selectedWallet, creditQueue } = useSelector((state) => state.user)
  const [updatedWalletData, setUpdatedWalletData] = useState(null)
  const { loginData } = useSelector(state => state.auth)

  const handleWalletUpdate = (data) => {
    setUpdatedWalletData(data?.data)
  }

  useEffect(() => {
    if (loginData?.accessToken) {
      const cleaner = listenWalletUpdateEvent(handleWalletUpdate)
      return () => cleaner()
    }
  }, [loginData?.accessToken])

  useEffect(() => {
    if (updatedWalletData) {
      const userWalletData = user?.wallets
      const updatedUserWalletData = userWalletData?.map(ele => {
        if (ele.id === updatedWalletData.id) {
          return { ...ele, amount: updatedWalletData.amount, nonCashAmount: updatedWalletData.nonCashAmount }
        } else {
          return ele
        }
      })
      if (selectedWallet?.currencyId === updatedWalletData?.currencyId) {
        if (updatedWalletData?.type === 'debit') {
          const updatedAmount = creditQueue?.length > 0
            ? minus(+selectedWallet.amount, +updatedWalletData?.betInfo?.betAmount)
            : updatedWalletData.amount

          dispatch(setSelectedWallet({
            ...selectedWallet,
            amount: +updatedAmount,
            nonCashAmount: updatedWalletData.nonCashAmount
          }))
        } else if (updatedWalletData?.type === 'credit') {
          dispatch(setCreditQueue({
            ...updatedWalletData
          }))
        } else {
          dispatch(setSelectedWallet({
            ...selectedWallet,
            amount: updatedWalletData.amount,
            nonCashAmount: updatedWalletData.nonCashAmount
          }))
        }
      }

      dispatch(setUserData({
        ...user,
        wallets: updatedUserWalletData
      }))
    }
  }, [updatedWalletData])

  return (
    <CurrencyDropdown {...props} />
  )
}

export default WalletSubscription
