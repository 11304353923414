import React from 'react'
// import { LoaderIcon } from 'components/ui-kit/Icons/svg/index'
import './mainLoader.scss'

const MainLoader = () => {
  return (
    <div className='main-loader'>
      {/* <LoaderIcon /> */}
    </div>
  )
}

export default MainLoader
