import { createAsyncThunk } from '@reduxjs/toolkit'
import { transactionType } from 'constants/index'
import { openInfoToaster } from 'helpers/toaster.helpers'
import { cryptoBetService, cryptoCashoutService, cryptoCoinsService, getBetPlaceTransactionService, getBetTransactionDetailService, updateBetService } from 'network/services/crypto.service'

export const cryptoBetPlace = createAsyncThunk('roller-coaster-game/place-bet',
  async ({ multiplier, betAmount, currencyId, isBuy, stopLossAmount, takeProfitAmount, setBetTransactionType, instrumentId, feeType }, thunkApi) => {
    try {
      const res = await cryptoBetService({ multiplier, betAmount, feeType, currencyId, isBuy, stopLossAmount, takeProfitAmount, instrumentId })
      setBetTransactionType(transactionType.ACTIVE_BETS)
      thunkApi.dispatch(getBetPlaceTransaction({
        pageNumber: 1,
        limit: 20,
        // instrumentId: instrumentId || '',
        rollercoaster: !instrumentId,
        filterType: transactionType.ACTIVE_BETS
      }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const cryptoCashout = createAsyncThunk('crypto/cash-out',
  async ({ betId, instrumentId, transactionType, setCashoutLoader }, thunkApi) => {
    try {
      const res = await cryptoCashoutService({ betId, instrumentId })
      thunkApi.dispatch(getBetPlaceTransaction({
        pageNumber: 1,
        limit: 20,
        // instrumentId: instrumentId || '',
        rollercoaster: !instrumentId,
        filterType: transactionType.ACTIVE_BETS
      }))
      setCashoutLoader(false)
      return res
    } catch (error) {
      setCashoutLoader(false)
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const getBetPlaceTransaction = createAsyncThunk(
  'roller-coaster-game/bets',
  async (data, thunkApi) => {
    try {
      const res = await getBetPlaceTransactionService(data)
      return { [data.filterType]: res, name: data.filterType }
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getBetTransactionDetail = createAsyncThunk(
  'roller-coaster-game/bet-detail',
  async (data, thunkApi) => {
    try {
      const res = await getBetTransactionDetailService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const updateBetDetail = createAsyncThunk(
  'roller-coaster-game/bet-update',
  async (data, thunkApi) => {
    try {
      const res = await updateBetService(data)
      openInfoToaster({ message: 'Bet update successfully' })
      thunkApi.dispatch(getBetPlaceTransaction({
        pageNumber: 1,
        limit: 20,
        // instrumentId: data.instrumentId || '',
        rollercoaster: !data.instrumentId,
        filterType: transactionType.ACTIVE_BETS
      }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getCryptoCoins = createAsyncThunk(
  'crypto-futures-game/instruments',
  async (_, thunkApi) => {
    try {
      const res = await cryptoCoinsService()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
