import React, { useMemo } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { ROUTE_PATHS, SPORTSBOOK_PATHS, TOOLTIP_VARIANT } from 'constants/index'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import {
  MyBetsIcon,
  LiveIcon,
  SportsbookFavouritesIcon,
  SoccerIcon,
  TennisIcon,
  BasketballIcon,
  VolleyballIcon,
  TableTennisIcon,
  GolfIcon,
  AmericanFootballIcon,
  BaseballIcon,
  SportsMenuIcon,
  OtherGamesIcon,
  HorseIcon
} from 'components/ui-kit/Icons/svg/index'
import { openInfoToaster } from 'helpers/toaster.helpers'

const SportsBookSection = (props) => {
  const { showSportsbookOptions } = props// setShowSportsbookOptions
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const { showSideNav } = useSelector((state) => state?.settings)

  const sportsbookOptions = useMemo(
    () => [
      {
        label: t('myBets'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.MY_BETS,
        iconComponent: MyBetsIcon
      },
      {
        label: t('live'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.LIVE,
        iconComponent: LiveIcon
      },
      {
        label: t('favourites'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.FAVOURITES,
        iconComponent: SportsbookFavouritesIcon
      },
      {
        label: t('soccer'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.SOCCER,
        iconComponent: SoccerIcon
      },
      {
        label: t('horseRacing'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.HORSE_RACING,
        iconComponent: HorseIcon
      },
      {
        label: t('tennis'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.TENNIS,
        iconComponent: TennisIcon
      },
      {
        label: t('basketball'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.BASKETBALL,
        iconComponent: BasketballIcon
      },
      {
        label: t('volleyball'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.VOLLEYBALL,
        iconComponent: VolleyballIcon
      },
      {
        label: t('tableTennis'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.TABLE_TENNIS,
        iconComponent: TableTennisIcon
      },
      {
        label: t('americanFootball'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.AMERICAN_FOOTBALL,
        iconComponent: AmericanFootballIcon
      },
      {
        label: t('baseball'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.BASEBALL,
        iconComponent: BaseballIcon
      },
      {
        label: t('golf'),
        id: uuidv4(),
        path: SPORTSBOOK_PATHS.GOLF,
        iconComponent: GolfIcon
      },
      {
        label: t('others'),
        id: uuidv4(),
        path: '',
        iconComponent: OtherGamesIcon
      }
    ],
    []
  )

  return (
    <>
      <li>
        <NavLink
          // to={ROUTE_PATHS.SPORTS_BOOK}
          to='#'
          onClick={() => openInfoToaster({ message: 'Coming soon' })}
          activeClassName={
        pathname === ROUTE_PATHS.SPORTS_BOOK ? 'active' : ''
      }
        >
          <CustomTooltip
            variant={TOOLTIP_VARIANT.SIDE_BAR}
            message={t('sports')}
            showTooltip={!showSideNav}
          >
            <div className='menu-left d-flex align-items-center'>
              <div className='menu-icon'>
                <SportsMenuIcon />
              </div>
              <p className='m-0'>{t('sports')}</p>
            </div>
          </CustomTooltip>
          <span
            className='menu-arrow'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              openInfoToaster({ message: 'Coming soon' })
              // setShowSportsbookOptions((prev) => !prev)
            }}
          >
            <i
              className={`fa fa-caret-${showSportsbookOptions ? 'up' : 'down'
            }`}
              aria-hidden='true'
            />
          </span>
        </NavLink>
        {showSportsbookOptions && showSideNav && (
          <ul className='submenu-wrap'>
            {sportsbookOptions.map((item) => (
              <li key={item.id}>
                <NavLink
                  to={`${ROUTE_PATHS.SPORTS_BOOK}?bt-path=%2F${item.path}`}
                  onClick={(e) => { }}
                  activeClassName={
                search === `?bt-path=%2F${item.path}`
                  ? 'active'
                  : ''
              }
                >
                  <item.iconComponent />
                  <span>{item.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </li>
      {showSportsbookOptions && <hr className='theme-divider' />}
    </>
  )
}

export default React.memo(SportsBookSection)
