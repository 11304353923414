import React, { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AddtoscreenIcon, AndroidIcon, CloseWhiteIcon, IosIcon } from 'components/ui-kit/Icons/svg/index'
import useAddToHomeScreenPrompt from 'hooks/useAddToHomeScreenPrompt'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { getFromSession, setItemToSession } from 'helpers/sessionstorage.helpers'
import './pwa.scss'

const PwaNotification = () => {
  const { t } = useTranslation()
  const [prompt, promptToInstall] = useAddToHomeScreenPrompt()
  const [showInstallationGuideMobilePopup, setShowInstallationGuideMobilePopup] = useState(false)
  const [showPwa, setShowPwa] = useState(true)

  const handleClick = () => {
    if (isAndroid) {
      promptToInstall()
    } else {
      setShowInstallationGuideMobilePopup(true)
    }
  }

  const handleClose = () => {
    setItemToSession('pwa_popup', true)
    setShowPwa(false)
  }

  useEffect(() => {
    const pwaPopupAlreadyShown = getFromSession('pwa_popup')
    setShowPwa(!pwaPopupAlreadyShown)
  }, [])

  return (
    ((showPwa && isAndroid && prompt) || (showPwa && isIOS)) &&
      <>
        <div
          className='mob-popup py-3 px-2 d-flex items-center justify-content-between gap-1'
        >
          <div className='gap-1 icons-wrap d-flex align-items-center'>
            {isAndroid && <AndroidIcon />}
            {isIOS && <IosIcon />}
            <div>
              <p className='mb-0 popup-title'> {t('addToHomeScreen')} </p>
              <p className='mb-0 popup-subtitle'>{t('oneTabAccess')}</p>
            </div>
          </div>
          <div className='d-flex gap-2 align-items-center '>
            <button
              className='btn btn-primary px-2 py-1'
              onClick={handleClick}
            >
              {t('install')}
            </button>
            <span
              onClick={handleClose}
            >
              <CloseWhiteIcon />
            </span>
          </div>
        </div>
        {showInstallationGuideMobilePopup &&
          <CommonPopup
            classes='add-to-screen-modal'
            modalCloseHandler={() => {
              setShowInstallationGuideMobilePopup(false)
            }}

          >
            <div className='d-flex flex-column justify-content-center gap-3 align-items-center text-center'>
              <IosIcon />
              <h3>{t('addToHome')}</h3>
              <p>{t('inChromeSafari')} <span> <AddtoscreenIcon /></span>  {t('buttonAndChoose')} <span> '{t('addToHome')}' </span> </p>
              <p>{t('afterCompletingThisStep')}</p>
            </div>
          </CommonPopup>}
      </>
  )
}

export default PwaNotification
