import React from 'react'
import { useSelector } from 'react-redux'
import { startCase } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { LOADER_HANDLER_TYPES, TRANSACTION_TABS } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import { getPrecision, toUtcTime } from 'utils/helperFunctions.utils'

const HighRollers = (props) => {
  const { tab } = props
  const { t } = useTranslation()
  const { highRollers } = useSelector(state => state.casino)
  const { currencies } = useSelector(state => state.user)
  const { [LOADER_HANDLER_TYPES.table]: loading } = useSelector(state => state.loader)
  const mainCurrencySymbol = currencies?.find(currency => currency?.primary)?.symbol

  return (
    <div
      className={`tab-pane fade show ${tab === TRANSACTION_TABS.HIGH_ROLLER ? 'active' : ''}`}
      id='lobby'
      role='tabpanel'
    >
      <div className='table-responsive mb-2'>
        <table className={`table theme-table theme-table-bets mb-2 animated-table ${highRollers?.rows?.length > 5 ? 'animated-table-gradient' : ''}`}>
          <thead>
            <tr>
              <th scope='col' className='text-start'>{t('gameText')}</th>
              <th scope='col' className='text-start'>{t('player')}</th>
              <th scope='col' className='text-start'>{t('time')}</th>
              <th scope='col' className='text-start'>{t('payoutText')}</th>
            </tr>
          </thead>
          <tbody>
            {highRollers?.rows?.length > 0
              ? highRollers?.rows?.map(data =>
                <tr key={uuidv4()}>
                  <td>
                    <div className='user-details d-flex align-items-center'>
                      <div className='user-image me-2'>
                        <img src={data?.casino?.image} height={32} width={24} />
                      </div>
                      {startCase(data?.casino?.gameName)}
                    </div>
                  </td>
                  <td>{startCase(data?.user?.userName)}</td>
                  <td>{toUtcTime(data?.createdAt, 'h:mm A')} {toUtcTime(data?.createdAt, 'MM/DD/YYYY')}</td>
                  <td>
                    <div className='text-success'>{`${mainCurrencySymbol} ${getPrecision(data?.amount, 2)?.toFixed(2)}`}</div>
                  </td>
                </tr>
                )
              : (
                <tr>
                  <td colSpan={4} className='text-center'>
                    {loading ? <Loader variant={LOADER_HANDLER_TYPES.table} /> : t('dataNotFound')}
                  </td>
                </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default React.memo(HighRollers)
