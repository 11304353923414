import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { userSignUp } from 'redux-thunk/thunk/auth.thunk'
import { openErrorToaster } from 'helpers/toaster.helpers'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
import SocialLinks from '../SocialLinks/index'
import { useQuery } from 'hooks/useQuery'
import { EyeCloseIcon, EyeIcon } from 'components/ui-kit/Icons/svg/index'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Signup = ({ setShowVerifyEmailPopup, showSignupPopup }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const [showPassword, setShowPassword] = useState({
    showPass: false,
    showConfirmPass: false
  })
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const schema = yup.object().shape({
    userName: yup
      .string()
      .required(`${t('signupErrorsUserNameRequired')}`)
      .min(2, `${t('signupErrorsUserNameMinLength', { minimumCharacters: 2 })}`)
      .max(20, `${t('signupErrorsUserNameMaxLength', { maximumCharacters: 20 })}`)
      .matches(/^\w+$/, t('signupErrorsUserNamePattern')),
    firstName: yup
      .string()
      .required(`${t('signupErrorsFirstNameRequired')}`)
      .min(2, `${t('signupErrorsUserNameMinLength', { minimumCharacters: 2 })}`)
      .max(30, `${t('signupErrorsUserNameMaxLength', { maximumCharacters: 30 })}`)
      .matches(/^[a-z\s]{0,255}$/i, t('signupErrorsStringPattern')),
    lastName: yup
      .string()
      .required(`${t('signupErrorsLastNameRequired')}`)
      .min(2, `${t('signupErrorsUserNameMinLength', { minimumCharacters: 2 })}`)
      .max(30, `${t('signupErrorsUserNameMaxLength', { maximumCharacters: 30 })}`)
      .matches(/^[a-z\s]{0,255}$/i, t('signupErrorsStringPattern')),
    phone: yup
      .string()
      .required(`${t('Phone number is required')}`)
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        'Invalid phone number format'
      ),
    phoneCode: yup
      .string()
      .required('Country code is required')
      .matches(/^\d+$/, 'Invalid country code format'),
    email: yup
      .string()
      .required(t('signupErrorsEmailRequired'))
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        t('signupErrorsEmailPattern')
      ),
    password: yup
      .string()
      .required(`${t('signupErrorsPasswordRequired')}`)
      .min(8, `${t('signupErrorsPasswordPattern')}`)
      .max(16, `${t('signupErrorsPasswordPattern')}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t('signupErrorsPasswordPattern')
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control // Used with Controller
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit'
  })

  const handleRegisterSubmit = async (userDetails) => {
    const affiliateCode = query.get('affiliateCode')
    const referralCode = query.get('referralCode')
    if (affiliateCode) {
      userDetails.affiliatedBy = affiliateCode
    }
    if (referralCode) {
      userDetails.referrerCode = referralCode
      userDetails.referralLink = window?.location?.href
    }
    try {
      dispatch(userSignUp({ userDetails, setShowVerifyEmailPopup }))
      reset()
    } catch (e) {
      openErrorToaster({ message: e.message })
    }
  }

  useEffect(() => {
    return () => {
      if (location.pathname === ROUTE_PATHS.SIGNUP) {
        history.push(ROUTE_PATHS.HOME)
      }
    }
  }, [])

  return (
    <div
      className={`tab-pane fade ${showSignupPopup && 'show active'}`}
      id='pills-profile'
      role='tabpanel'
      aria-labelledby='pills-profile-tab'
    >
      <div className='register-wrap pb-5 pb-sm-0'>
        <form onSubmit={handleSubmit(handleRegisterSubmit)}>
          <div className='mb-3 form-group'>
            <label htmlFor='user_name' className='form-label text-uppercase'>
              {t('signupUsername')}
            </label> <span className='color-red'>*</span>
            <input
              type='text'
              placeholder={t('signupUsername')}
              className={`form-control form-control-dark shadow-none ${errors?.userName ? 'error-input' : ''}`}
              id='user_name'
              name='userName'
              {...register('userName')}
              aria-describedby=''
              maxLength={50}
            />
            {errors && errors.userName && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.userName.message}
                />
              </div>
            )}
          </div>
          <div className='mb-3 d-flex gap-2'>
            <div className='form-group'>
              <label htmlFor='firstName' className='form-label text-uppercase'>
                {t('firstName')}
              </label> <span className='color-red'>*</span>
              <input
                type='text'
                placeholder={t('firstName')}
                className={`form-control form-control-dark shadow-none ${errors?.firstName ? 'error-input' : ''}`}
                id='firstName'
                name='firstName'
                {...register('firstName')}
                aria-describedby=''
                maxLength={50}
              />
              {errors && errors.firstName && (
                <div className='error-container'>
                  <ErrorMessage
                    className='color-red text-danger error-msg login-input ms-2 d-flex'
                    message={errors.firstName.message}
                  />
                </div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='lastName' className='form-label text-uppercase'>
                {t('lastName')}
              </label> <span className='color-red'>*</span>
              <input
                type='text'
                placeholder={t('lastName')}
                className={`form-control form-control-dark shadow-none ${errors?.lastName ? 'error-input' : ''}`}
                id='lastName'
                name='lastName'
                {...register('lastName')}
                aria-describedby=''
                maxLength={50}
              />
              {errors && errors.lastName && (
                <div className='error-container'>
                  <ErrorMessage
                    className='color-red text-danger error-msg login-input ms-2 d-flex'
                    message={errors.lastName.message}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='mb-3 form-group'>
            <label htmlFor='phone' className='form-label text-uppercase'>
              {t('phone')}
            </label> <span className='color-red'>*</span>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <PhoneInput
                  country='us'
                  value={field.value}
                  onChange={(value, { dialCode }) => {
                    setValue('phone', value, { shouldValidate: true })
                    setValue('phoneCode', dialCode, { shouldValidate: true })
                  }}
                  inputClass={`form-control ${errors.phone ? 'error-input' : ''}`}
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '0.625rem'
                  }}
                />
              )}
            />
            {errors && errors.phone && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors?.phone?.message}
                />
              </div>
            )}
          </div>
          <div className='mb-3 form-group'>
            <label htmlFor='email' className='form-label text-uppercase'>
              {t('email')}
            </label> <span className='color-red'>*</span>
            <input
              type='email'
              placeholder={t('emailPlaceholder')}
              className={`form-control form-control-dark shadow-none ${errors?.email ? 'error-input' : ''}`}
              id='email'
              aria-describedby=''
              name='email'
              maxLength={50}
              {...register('email')}
            />
            {errors && errors.email && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.email.message}
                />
              </div>
            )}
          </div>
          <div className='mb-4 form-group'>
            <label
              htmlFor='user_password'
              className='form-label text-uppercase'
            >
              {t('signupPassword')}
            </label> <span className='color-red'>*</span>
            <input
              type={showPassword.showPass ? 'text' : 'password'}
              placeholder={t('********')}
              className={`form-control form-control-dark shadow-none ${errors?.password ? 'error-input' : ''}`}
              id='user_password'
              name='password'
              maxLength={50}
              {...register('password')}
            />
            <a
              onClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  showPass: !prevState.showPass
                }))}
              className='show-password'
            >
              {!showPassword.showPass
                ? (
                  <EyeIcon />
                  )
                : (
                  <EyeCloseIcon />
                  )}
            </a>
            {errors && errors.password && (
              <div className='error-container'>
                <ErrorMessage
                  className='color-red text-danger error-msg login-input ms-2 d-flex'
                  message={errors.password.message}
                />
              </div>
            )}
          </div>
          <div className='auth-btn-wrap'>
            <button
              type='submit'
              className='btn btn-secondary w-100'
              disabled={loading}
            >
              {loading
                ? (
                  <Loader variant={LOADER_HANDLER_TYPES.submit} />
                  )
                : (
                  <span>{t('startplaying')}</span>
                  )}
            </button>
          </div>
          <div className='auth-socail-wrap'>
            <div className='auth-social-heading'>
              <h6>
                <span>{t('orContinueWith')}</span>
              </h6>
            </div>
          </div>
          <SocialLinks isLoginSignup />
          <div className='auth-mob-instructions d-block d-lg-none accordion pb-5 pb-md-0'>
            <p>
              {t('usersConsent')}
              <NavLink to='#'>{t('termsOfService')}</NavLink>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Signup
