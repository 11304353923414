import ErrorBoundary from 'components/layout/ErrorBoundary/index'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer } from 'react-toastify'
import store from 'redux-thunk/store'
import Routes from 'routes/index'
import config from 'config/app.config'
import PwaNotification from 'components/layout/PWA/index'
import 'react-toastify/dist/ReactToastify.css'

function App () {
  useEffect(() => {
    Promise.all(Array.from(document.images)
      .filter(img => !img.complete)
      .map(img => new Promise(resolve => { img.onload = img.onerror = resolve })))
      .then(() => {
      })
  }, [])

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <ErrorBoundary>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <PwaNotification />
          <Routes />
        </ErrorBoundary>
      </Provider>
    </GoogleOAuthProvider>
  )
}

export { store }

export default App
