import { createAsyncThunk } from '@reduxjs/toolkit'
import { openSuccessToaster } from 'helpers/toaster.helpers'
import { generatePaymentTokenService, wallet88EmtService, wallet88PaymentService } from 'network/services/payment.service'
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'

export const generatePaymentToken = createAsyncThunk(
  'post/generate-payment-token',
  async (data, thunkApi) => {
    try {
      const res = await generatePaymentTokenService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const initiateWithdrawRequest = createAsyncThunk(
  'post/initiate-withdraw',
  async (data, thunkApi) => {
    try {
      const res = await generatePaymentTokenService(data)
      openSuccessToaster({ message: 'Withdraw request initiated' })
      thunkApi?.dispatch(setShowCashierPopup(false))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const bankTransfer = createAsyncThunk(
  'post/wallet88-payment-bank-transfer',
  async (data, thunkApi) => {
    try {
      const res = await wallet88PaymentService(data)
      const blob = new Blob([res.data.data.content], { type: 'text/html' })
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
      thunkApi?.dispatch(setShowCashierPopup(false))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const intiEmtWallet88 = createAsyncThunk(
  'post/wallet88-payment-EMT',
  async (data, thunkApi) => {
    try {
      const res = await wallet88EmtService(data)
      thunkApi?.dispatch(setShowCashierPopup(false))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
