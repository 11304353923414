import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { startCase } from 'lodash'
import { useDebounce } from 'react-use'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { SearchIconIcon } from 'components/ui-kit/Icons/svg/index'
import { capitalizeWords } from 'utils/helperFunctions.utils'
import { SORT_BY_OPTIONS } from 'constants/index'
import { useClickOutside } from '../../../hooks/useClickOutside'

const ProvidersSearchBar = (props) => {
  const {
    setName,
    setProvider,
    provider,
    showProvidersDropdown,
    sortBy,
    setSortBy
  } = props
  const { t } = useTranslation()
  const { providers } = useSelector((state) => state.games)
  const [selectedProviderName, setSelectedProviderName] = useState('All')
  const [searchVal, setSearchVal] = useState('')
  const [inputProvider, setInputProvider] = useState('')
  const [filteredProviders, setFilteredProviders] = useState(providers)
  const [isProviderDropdownOpen, setIsProviderDropdownOpen] = useState(false)
  const [isPopularityDropdownOpen, setIsPopularityDropdownOpen] = useState(false)
  const providersDropdownRef = useRef(null)
  const popularityDropdownRef = useRef(null)

  const handleSearchGame = (e) => {
    setSearchVal(e?.target?.value)
  }

  const handleSearchProvider = (e) => {
    setInputProvider(e?.target?.value)
  }

  useClickOutside(providersDropdownRef, (e) => {
    const wrapper = providersDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(wrapper.querySelector('.dropdown-toggle'))
    if (isProviderDropdownOpen) {
      dropdown.hide()
    }
    setIsProviderDropdownOpen(false)
  })

  useClickOutside(popularityDropdownRef, () => {
    const wrapper = popularityDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(wrapper.querySelector('.dropdown-toggle'))
    if (isPopularityDropdownOpen) {
      dropdown.hide()
    }
    setIsPopularityDropdownOpen(false)
  })

  const togglePopularityDropdown = useCallback((e) => {
    setIsPopularityDropdownOpen(!isPopularityDropdownOpen)
    const wrapper = popularityDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(wrapper.querySelector('.dropdown-toggle'))
    if (isPopularityDropdownOpen) {
      dropdown.hide()
    } else {
      dropdown.show()
    }
  }, [isPopularityDropdownOpen])

  const toggleProvidersDropdown = useCallback((e) => {
    setIsProviderDropdownOpen(!isProviderDropdownOpen)
    const wrapper = providersDropdownRef.current
    const dropdown = new window.bootstrap.Dropdown(wrapper.querySelector('.dropdown-toggle'))
    if (isProviderDropdownOpen) {
      dropdown.hide()
    } else {
      dropdown.show()
    }
  }, [isProviderDropdownOpen])

  useDebounce(
    () => {
      setName(searchVal)
    },
    1000,
    [searchVal]
  )

  useEffect(() => {
    if (inputProvider === '') {
      setFilteredProviders(providers)
    } else {
      setFilteredProviders({
        rows: providers.rows.filter((item) => {
          if (
            item?.name?.toLowerCase()?.includes(inputProvider.toLowerCase())
          ) {
            return item
          }
          return false
        })
      })
    }
  }, [inputProvider])

  useEffect(() => {
    setFilteredProviders(providers)
  }, [providers])

  return (
    <section className='casino-search d-md-flex align-items-center justify-content-between mb-3'>
      <div className='casino-search-content w-100 me-2 mb-2 mb-md-0'>
        <input
          type='text'
          className='form-control shadow-none'
          value={searchVal}
          id='casinoSearch'
          placeholder={t('searchGames')}
          autoFocus={!isMobile}
          onChange={(e) => {
            handleSearchGame(e)
          }}
        />
        <SearchIconIcon className='search-icon' />
      </div>
      {showProvidersDropdown && (
        <div className='search-filter-right d-flex align-items-center'>
          <div
            className='btn-group me-2'
            ref={providersDropdownRef}
          >
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='providersDropdown'
              aria-haspopup='true'
              onClick={toggleProvidersDropdown}
            >
              {t('provider')}: <span>{startCase(selectedProviderName)}</span>
            </button>
            <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='providersDropdown'
            >
              <section className='casino-search'>
                <div className='casino-search-content'>
                  <input
                    type='text'
                    className='form-control shadow-none'
                    id='providerSearch'
                    placeholder={`${t('search')}...`}
                    value={inputProvider}
                    onChange={(e) => handleSearchProvider(e)}
                  />
                  <SearchIconIcon className='search-icon' />
                </div>
              </section>
              <div className='provider-list-wrap'>
                <li>
                  <a
                    className={`dropdown-item ${
                      provider === 'All' ? 'active' : ''
                    }`}
                    href=''
                    onClick={(e) => {
                      e.preventDefault()
                      setProvider('')
                      setSelectedProviderName('All')
                      toggleProvidersDropdown(!isProviderDropdownOpen)
                    }}
                  >
                    {t('all')}
                  </a>
                </li>
                {filteredProviders?.rows?.map((item) => (
                  <li key={item?.providerId}>
                    <a
                      className={`dropdown-item ${
                        provider === item?.providerId ? 'active' : ''
                      }`}
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        setProvider(item?.providerId)
                        setSelectedProviderName(item?.name)
                        toggleProvidersDropdown(!isProviderDropdownOpen)
                      }}
                    >
                      {capitalizeWords(item?.name)}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div
            className='btn-group'
            ref={popularityDropdownRef}
          >
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              id='providersPopularity'
              aria-haspopup='true'
              onClick={togglePopularityDropdown}
            >
              {t('sortBy')}: <span>{sortBy}</span>
            </button>
            <ul
              className='dropdown-menu provider-list search-provider-list'
              aria-labelledby='providersPopularity'
            >
              <div className='provider-list-wrap'>
                {Object.keys(SORT_BY_OPTIONS)?.map((key) =>
                  <li key={key}>
                    <a
                      className='dropdown-item'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        togglePopularityDropdown(!isPopularityDropdownOpen)
                        setSortBy(key)
                      }}
                    >{key}
                    </a>
                  </li>)}
              </div>
            </ul>
          </div>
        </div>
      )}
    </section>
  )
}

export default React.memo(ProvidersSearchBar)
