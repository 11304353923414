import { CryptoMenuIcon } from 'components/ui-kit/Icons/svg/index'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import { ROUTE_PATHS, TOOLTIP_VARIANT } from 'constants/index'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import RollerCoasterComp from '../RollerCoasterPrice/index'
import { cryptoIcon } from 'utils/helperFunctions.utils'
import CryptoPrice from '../CryptoPrice/index'
import { setShowCryptoAcknowledgementPopup } from 'redux-thunk/redux/slices/settings.slice'
import { openInfoToaster } from 'helpers/toaster.helpers'

const CryptoSection = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchCoin, setSearchCoin] = useState('')
  const { showCryptoOptions, setShowCasinoOptions, showCasinoOptions } = props// setShowCryptoOptions
  const { pathname } = useLocation()
  const { cryptoCoinsList } = useSelector((state) => state.crypto)
  const [coins, setCoins] = useState([])
  const { loginData } = useSelector((state) => state.auth)
  const { showSideNav, showCryptoAcknowledgement } = useSelector((state) => state?.settings)
  const showCryptosList = showCryptoOptions && showSideNav && cryptoCoinsList.length > 0

  useEffect(() => {
    const filteredCoin = cryptoCoinsList?.filter(item => item.name.toLowerCase().includes(searchCoin.toLowerCase()) ||
     item.symbol.includes(searchCoin.toUpperCase()))
    setCoins(filteredCoin)
  }, [searchCoin, cryptoCoinsList])

  return (
    <>
      <li>
        <NavLink
          // to={`${ROUTE_PATHS.CRYPTO}/${coins[0]?.id}`}
          to='#'
          activeClassName=''
          // className={
          //           pathname === ROUTE_PATHS.ROLLERCOASTER ||
          //             pathname.match(`${ROUTE_PATHS.CRYPTO}`)
          //             ? 'active'
          //             : ''
          //         }
          onClick={() => {
            openInfoToaster({ message: 'Coming soon' })
            // TODO UNCOMMENT WHEN NEED CRYPTO
            // if (!loginData?.accessToken) {
            //   setShowCryptoOptions((prev) => !prev)
            // } else {
            //   showCryptoAcknowledgement && dispatch(setShowCryptoAcknowledgementPopup(true))
            //   setShowCryptoOptions((prev) => !prev)
            //   dispatch(setCryptoCoin({ name: coins[0]?.name, id: coins[0]?.id }))
            // }
          }}
        >
          <CustomTooltip
            variant={TOOLTIP_VARIANT.SIDE_BAR}
            message={t('crypto')}
            showTooltip={!showSideNav}
          >
            <div className='menu-left d-flex align-items-center'>
              <div className='menu-icon'>
                <CryptoMenuIcon />
              </div>
              <p className='m-0'>{t('crypto')}</p>
            </div>
          </CustomTooltip>
          <span
            className='menu-arrow'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              // TODO UNCOMMENT WHEN CRYPTO IS ENABLED
              openInfoToaster({ message: 'Coming soon' })
              // setShowCryptoOptions((prev) => !prev)
            }}
          >
            <i
              className={`fa fa-caret-${showCryptoOptions ? 'up' : 'down'}`}
              aria-hidden='true'
            />
          </span>
        </NavLink>
        {showCryptosList && (
          <>
            <input
              type='search'
              onChange={(e) => setSearchCoin(e.target.value)}
              value={searchCoin}
              className='crypto-search-input form-control form-control-dark'
              placeholder='Search'
              style={{ width: '200px', height: '30px', marginTop: '8px', marginBottom: '4px' }}
            />
            <ul className='submenu-wrap crypto-cont'>
              {coins?.map((item, index) => (
                <li
                  key={index}
                  className={`crypto-list-wrap ${pathname === `${ROUTE_PATHS.CRYPTO}/${item?.id}`
                          ? 'active'
                          : ''
                          }`}
                >
                  <NavLink
                    to={`${ROUTE_PATHS.CRYPTO}/${item?.id}`}
                    onClick={(e) => {
                      if (!loginData?.accessToken) {
                        dispatch(setShowLoginPopup(true))
                      } else {
                        showCryptoAcknowledgement && dispatch(setShowCryptoAcknowledgementPopup(true))
                      }
                      showCasinoOptions &&
                              dispatch(setShowCasinoOptions(false))
                      dispatch(setCryptoCoin({ name: item?.name, id: item?.id }))
                    }}
                    className='crypto-list d-flex align-items-center justify-content-between'
                  >
                    <div className='d-flex align-items-center crypto-list-left'>
                      <img src={cryptoIcon(item?.id)} alt={item?.name} className='crypto-img' />
                      <span>{item?.symbol.toUpperCase()}</span>
                    </div>
                    <CryptoPrice changeShow id={item?.id} />
                  </NavLink>
                </li>
              ))}
              {'nextgen coaster'.includes(searchCoin.toLowerCase()) && <RollerCoasterComp />}
            </ul>
          </>
        )}
      </li>
      {showCryptoOptions && <hr className='theme-divider' />}
    </>
  )
}

export default CryptoSection
