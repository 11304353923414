import React, { lazy, useEffect, useMemo } from 'react'
import { S3_IMAGES_URL } from 'constants/index'
import BannerSection from './components/BannerSection/index'
import ClutchEcosystem from './components/ClutchEcosystem/index'
// import ClutchTrading from './components/ClutchTrading/index'
// import Poker from './components/Poker/index'
// import Nft from './components/Nft/index'
import TransactionsTable from './components/TransactionsTable/index'
import PaymentSection from './components/PaymentSection/index'
import { useSelector } from 'react-redux'
import SuspenseWrapper from 'components/molecules/SuspenseWrapper/index'
import ProvidersListing from 'pages/Casino/components/Lobby/Components/ProvidersListing/index'
import './home.scss'
import SubpageHeader from '../../components/ui-kit/SubpageHeader'

const CasinoSection = lazy(() => import('pages/Home/components/CasinoSection'))
// const SportsbookSection = lazy(() => import('pages/Home/components/SportsbookSection'))
// const CryptoFutures = lazy(() => import('pages/Home/components/CryptoFutures/index'))
// const HomePromotions = lazy(() => import('./components/HomePromotions'))
const OriginalsGames = lazy(() => import('pages/Casino/components/Lobby/Components/OriginalsGames'))
const ThirdPartyLogin = lazy(() => import('./components/ThirdPartyLogin'))

function Home () {
  const { loginData } = useSelector(state => state.auth)
  // const { cryptoCoinsList } = useSelector((state) => state.crypto)

  const providersSliderSettings = useMemo(() => (
    {
      initialSlide: 0,
      dots: false,
      infinite: true,
      arrows: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 8
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    }
  ), [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='mb-4'>
        <SubpageHeader
          img={`${S3_IMAGES_URL.IMAGE_URL}/images/home_banner.webp`}
          mobileImg={`${S3_IMAGES_URL.IMAGE_URL}/images/home_banner_mobile.webp`}
        >
          <BannerSection />
        </SubpageHeader>
      </div>
      <ClutchEcosystem />
      <PaymentSection className='pb-4' />
      {/* <SuspenseWrapper Component={HomePromotions} /> */}
      {/* <LiveWins /> */}
      <SuspenseWrapper Component={OriginalsGames} />
      <SuspenseWrapper Component={CasinoSection} />
      {/* <SuspenseWrapper Component={SportsbookSection} /> */}
      <TransactionsTable />
      {/* {cryptoCoinsList?.length > 0 && <SuspenseWrapper Component={CryptoFutures} />} */}
      {/* <ClutchTrading /> */}
      {/* <Poker /> */}
      {/* <Nft /> */}
      <SuspenseWrapper Component={ProvidersListing} properties={{ homeCasinoSettings: providersSliderSettings }} />
      {!(loginData?.accessToken) &&
        <section className='mb-4'>
          <ThirdPartyLogin />
        </section>}
    </div>
  )
}

export default Home
