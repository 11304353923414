import React from 'react'
import Slider from 'react-slick'

const CustomSlider = React.forwardRef((props, ref) => {
  const propsWithBlur = {
    ...props,
    beforeChange: () => document.activeElement?.blur()
  }
  return (
    <Slider {...propsWithBlur} ref={ref}>
      {props.children}
    </Slider>
  )
})

export default CustomSlider
