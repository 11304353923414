import React from 'react'
import { useTranslation } from 'react-i18next'

const Community = () => {
  const { t } = useTranslation()

  return (
    <ul className='m-0'>
      <li>
        <a href='https://www.discord.gg/3TU9ANdTxE' target='_blank' rel='noopener noreferrer'>
          {t('discord')}
        </a>
        {/* <NavLink to='#'>{t('instagram')}</NavLink> */}
      </li>
      <li>
        <a href='https://twitter.com/TagboxTokens' target='_blank' rel='noopener noreferrer'>
          {t('twitter')}
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/nextgenimmersivemedia' target='_blank' rel='noopener noreferrer'>
          {t('instagram')}
        </a>
      </li>
      <li>
        <a href='https://www.threads.net/nextgenimmersivemedi' target='_blank' rel='noopener noreferrer'>
          Threads
        </a>
      </li>
      <li>
        <a href='https://www.tiktok.com/@nextgen_games' target='_blank' rel='noopener noreferrer'>
          Tiktok
        </a>
      </li>
      <li>
        <a href='https://kick.com/nextgengames' target='_blank' rel='noopener noreferrer'>
          Kick
        </a>
      </li>
    </ul>
  )
}

export default React.memo(Community)
