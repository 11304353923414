import { lazy } from 'react'
import Home from 'pages/Home'
import { ROUTE_PATHS } from 'constants/index'
const Casino = lazy(() => import('pages/Casino'))
// const BetHistory = lazy(() => import('pages/ProfileContentWrapper/ContentPage/BetHistoryContainer'))
// const ChangePasswordSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/ChangePasswordSection'))
// const DepositSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/DepositSection'))
// const GameLimitSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/GameLimitSection'))
// const ProfileDetails = lazy(() => import('pages/ProfileContentWrapper/ContentPage/ProfileDetails'))
// const ReferralSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/ReferralSection'))
// const TransactionSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/TransactionSection'))
// const WithdrawSection = lazy(() => import('pages/ProfileContentWrapper/ContentPage/WithdrawSection'))
const Profile = lazy(() => import('pages/Profile'))
const CrashGame = lazy(() => import('pages/NewCrashGame'))
const FlipCoinGame = lazy(() => import('pages/FlipCoinGame'))
const NewMineGame = lazy(() => import('pages/NewMineGame'))
const PlinkoGame = lazy(() => import('pages/PlinkoGame'))
const HiloGame = lazy(() => import('pages/HiLoGame'))
// const ProfileWrapper = lazy(() => import('pages/ProfileContentWrapper/index'))
const VerifyEmail = lazy(() => import('pages/VerifyEmail/index'))
const ForgetPassword = lazy(() => import('pages/ForgetPassword'))
// const About = lazy(() => import('pages/About'))
// const TermsCondition = lazy(() => import('pages/SiteInfo/TermsCondition'))
const SiteInfo = lazy(() => import('pages/SiteInfo'))
const SportsBook = lazy(() => import('pages/SportsBook'))
const GameContainer = lazy(() => import('pages/GameContainer'))
const Promotions = lazy(() => import('pages/Promotion'))
const Crypto = lazy(() => import('pages/Crypto'))
const Providers = lazy(() => import('pages/Providers/index'))
const ProvidersGames = lazy(() => import('pages/Providers/ProviderGames'))
const Rewards = lazy(() => import('pages/Rewards'))
const PromotionDetails = lazy(() => import('pages/Promotion/components/PromotionDetails/index'))
const PreLoginRewards = lazy(() => import('pages/PreLoginPages/Rewards/index'))
const PreLoginAffiliates = lazy(() => import('pages/PreLoginPages/Affiliates/index'))
const UsElections = lazy(() => import('pages/UsElections/index'))

const routesList = [
  {
    path: ROUTE_PATHS.HOME,
    component: Home,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.LOGIN,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.SIGNUP,
    showHeader: true,
    showFooter: true,
    component: Home
  },
  {
    path: ROUTE_PATHS.CRASH_GAME,
    component: CrashGame,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CRASH_GAME_DEMO,
    component: CrashGame,
    isPrivate: false,
    showHeader: true,
    showFooter: false
  },
  {
    path: ROUTE_PATHS.FLIP_COIN_GAME_DEMO,
    component: FlipCoinGame,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.FLIP_COIN_GAME,
    component: FlipCoinGame,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.MINE_GAME,
    component: NewMineGame,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PLINKO_GAME,
    component: PlinkoGame,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.HILO_GAME,
    component: HiloGame,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  // {
  //   path: ROUTE_PATHS.PROFILE.ROOT,
  //   component: ProfileWrapper,
  //   child: ProfileDetails,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.BET_HISTORY,
  //   component: ProfileWrapper,
  //   child: BetHistory,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.TRANSACTIONS,
  //   component: ProfileWrapper,
  //   child: TransactionSection,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.DEPOSIT,
  //   component: ProfileWrapper,
  //   child: DepositSection,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.CASHOUT,
  //   component: ProfileWrapper,
  //   child: WithdrawSection,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.GAME_LIMITS,
  //   component: ProfileWrapper,
  //   child: GameLimitSection,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.REFERRAL,
  //   component: ProfileWrapper,
  //   child: ReferralSection,
  //   isPrivate: true
  // }, {
  //   path: ROUTE_PATHS.PROFILE.CHANGE_PASSWORD,
  //   component: ProfileWrapper,
  //   child: ChangePasswordSection,
  //   isPrivate: true
  // },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL,
    component: VerifyEmail,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: ForgetPassword,
    showHeader: true,
    showFooter: true
  },
  // {
  //   path: ROUTE_PATHS.ABOUT,
  //   component: About,
  //   showHeader: true,
  //   showFooter: true
  // },
  {
    path: ROUTE_PATHS.SITE_INFO,
    component: SiteInfo,
    showHeader: true,
    showFooter: true
  },
  // {
  //   path: ROUTE_PATHS.TERMS_AND_CONDITION,
  //   component: TermsCondition,
  //   showHeader: true,
  //   showFooter: true
  // },
  {
    path: [ROUTE_PATHS.CASINO, ROUTE_PATHS.GAME_CATAGORY],
    component: Casino,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.SPORTS_BOOK,
    component: SportsBook,
    showHeader: true,
    isPrivate: false
  },
  {
    path: ROUTE_PATHS.CASINO_PLAYGAME,
    component: GameContainer,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROMOTIONS,
    component: Promotions,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROMOTION_DETAILS,
    component: PromotionDetails,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: [
      ROUTE_PATHS.PROFILE,
      ROUTE_PATHS.BALANCE,
      ROUTE_PATHS.DEPOSIT,
      ROUTE_PATHS.WITHDRAWALS,
      ROUTE_PATHS.REFERRALS,
      ROUTE_PATHS.SETTINGS,
      ROUTE_PATHS.TRANSACTIONS,
      ROUTE_PATHS.ACCOUNT_VERIFICATION,
      ROUTE_PATHS.LIMITS
    ],
    component: Profile,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.ROLLERCOASTER,
    component: Crypto,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CRYPTO_CATEGORY,
    component: Crypto,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROVIDERS,
    component: Providers,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROVIDERS_CATEGORY,
    component: ProvidersGames,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.REWARDS,
    component: Rewards,
    isPrivate: true,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PRE_LOGIN_REWARDS,
    component: PreLoginRewards,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PRE_LOGIN_AFFILIATES,
    component: PreLoginAffiliates,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.US_ELECTIONS,
    component: UsElections,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  }
]

export default routesList
