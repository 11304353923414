import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, NavLink } from 'react-router-dom'
import { startCase } from 'lodash'
// import { goldICon, vipRanked } from 'components/ui-kit/Icons/png/index' // vipRanked
import {
  setChatCollapse,
  setShowChat,
  setShowProfileChangePopup,
  // setShowTipPopup,
  setShowUserDetailsPopup
} from 'redux-thunk/redux/slices/settings.slice'
import Loader from 'components/ui-kit/Loader/index'
import { LOADER_HANDLER_TYPES, ROUTE_PATHS } from 'constants/index'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import { ArrowRightShortIcon, SkullBadgeIcon, MostPlayedIcon } from 'components/ui-kit/Icons/svg/index'
import ContentNotFound from 'components/ui-kit/ContentNotFound/index'
// import SocialMedia from './components/SocialMedia/index'
// import Badges from './components/Badges/index'
import Statistics from './components/Statistics/index'
import { useLoginBeforeRedirect } from 'hooks/useLoginBeforeRedirect'

const UserDetailsPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { loginData } = useSelector((state) => state.auth)
  const { userData } = useSelector((state) => state.chat)
  const { [LOADER_HANDLER_TYPES.userDetails]: loading } = useSelector(
    (state) => state.loader
  )
  const { user } = useSelector((state) => state.user)
  const { handleLoginBeforeRedirect } = useLoginBeforeRedirect()

  const modalCloseHandler = () => {
    dispatch(setShowUserDetailsPopup(false))
  }

  const handleCloseChat = useCallback(() => {
    dispatch(setShowChat(false))
    dispatch(setChatCollapse(true))
  })

  const handleAllGamesClick = useCallback(() => {
    modalCloseHandler()
  })

  const onGamePlayRedirect = useCallback((game) => {
    const gameName = game?.gameName?.toLowerCase().replace(/\s+/g, '-')
    if (loginData?.accessToken) {
      modalCloseHandler()
      handleCloseChat()
      history?.push(`/casino/play-game/${gameName}`, { game, demo: false })
    } else if (game?.hasDemo) {
      modalCloseHandler()
      handleCloseChat()
      history?.push(`/casino/play-game/${gameName}`, { game, demo: true })
    } else {
      handleLoginBeforeRedirect(`/casino/play-game/${gameName}`)
    }
  }, [loginData])

  // const handleTipping = useCallback(() => {
  //   dispatch(setShowTipPopup(true))
  //   const element = document.querySelector('.user-profile-modal')
  //   element?.classList?.add('remove-blur')
  // })

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes='chat-modal user-profile-modal'
      id='login_modal'
    >
      {loading
        ? (
          <Loader
            variant={LOADER_HANDLER_TYPES.content}
            style={{ minHeight: '300px' }}
          />
          )
        : (
          <>
            <div className='chat-modal-header-wrap'>
              <div className='chat-modal-profile-header'>
                <div className='chat-modal-profile-img d-flex align-items-center justify-content-center'>
                  {userData?.profileImageUrl
                    ? <img src={userData?.profileImageUrl} alt='Profile Img' />
                    : <SkullBadgeIcon height={80} width={80} />}
                </div>
                <div className='chat-modal-header-details d-flex align-items-center'>
                  {/* <div className='chat-modal-header-left mx-2'>
                    <img src={userData?.userRankDetails?.imageLogo || vipRanked} height={62} width={62} alt='Vip ranked' />
                  </div> */}
                  <div className='chat-modal-header-right mx-2'>
                    <div className='d-flex align-items-center mb-1'>
                      <h4 className='m-0'>{userData?.name}</h4>
                      {/* {userData?.name !== user?.userName &&
                    loginData?.accessToken && (
                      <button
                        type='button'
                        className='btn tip-btn'
                        // onClick={handleTipping}
                      >
                        <img src={goldICon} alt='Coin' className='me-2' />
                        <span className='text'>{t('tip')}</span>
                      </button>
                      )} */}
                      {userData?.id.toString() === user?.id?.toString() &&
                        <button
                          type='button'
                          className='btn edit-btn'
                          onClick={() => {
                            dispatch(setShowProfileChangePopup(true))
                            const element = document.querySelector('.user-profile-modal')
                            element?.classList?.add('remove-blur')
                          }}
                        >
                          <i className='fas fa-pencil-alt cursor-pointer' />
                        </button>}
                    </div>
                    {/* <div className='progrees-content align-items-center justify-content-between'>
                      <p className='m-0'>
                        {t('rank')} :
                        <span
                          className='text-uppercase'
                          style={{ color: userData?.userRankDetails?.moreDetails?.color }}
                        >{userData?.userRankDetails?.rank}
                        </span>
                      </p>
                    </div>
                    <div className='progress'>
                      <div
                        className='progress-bar'
                        role='progressbar'
                        aria-valuenow={userData?.userWagerAmount || 0}
                        aria-valuemin='0'
                        aria-valuemax={userData?.nextRankingDetails?.[0]?.wagerRequirement}
                        style={{
                          width: `${(((+userData?.userWagerAmount) / (+userData?.nextRankingDetails?.[0]?.wagerRequirement)) * 100)}%`,
                          backgroundColor: userData?.userRankDetails?.moreDetails?.color
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <SocialMedia /> */}
            {/* <Badges /> */}
            {!userData?.isPrivate && <Statistics />}
            <div className='chat-modal-content'>
              <div className='chat-inner-header d-flex align-items-center justify-content-between'>
                <div className='chat-badge-header-left d-flex align-items-center'>
                  <MostPlayedIcon />
                  <h4 className='m-0'>{startCase(t('mostPlayed'))}</h4>
                </div>
                <NavLink
                  to={ROUTE_PATHS.CASINO}
                  className='d-flex align-items-center'
                  onClick={handleAllGamesClick}
                >
                  {t('allGames')}
                  <ArrowRightShortIcon />
                </NavLink>
              </div>
              {userData?.mostPlayedGameDetails?.length > 0
                ? (
                  <section className='global-casino-section pb-0'>
                    <div className='row px-2'>
                      {userData?.mostPlayedGameDetails?.slice(0, 4)?.map(game =>
                        <div key={game?.id} className='col-3 col-lg-3 px-1'>
                          <div className='theme-card-wrap'>
                            <div
                              className='theme-card'
                              onClick={() => onGamePlayRedirect(game)}
                            >
                              <div className='casino-img-wrap'>
                                <img src={game?.image} alt='Mine' />
                                {/* <div className='card-overlay'>
                                <h4>Challenge Pool</h4>
                                <p>$1,130.33</p>
                                <a href=''>
                                  <FavouritesWIcon />
                                </a>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </div>)}
                    </div>
                  </section>)
                : <ContentNotFound message={t('nothingFound')} />}
            </div>
          </>
          )}
    </CommonPopup>
  )
}

export default UserDetailsPopup
