import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomSlider from 'components/ui-kit/CustomSlider'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GamesProviderHeadingIcon } from 'components/ui-kit/Icons/svg/index'
import { provider1 } from 'components/ui-kit/Icons/png/index'
import {
  setViewAllGames
} from 'redux-thunk/redux/slices/games.slice'
import { GameImg } from 'pages/GameImage/index'
import { ROUTE_PATHS } from 'constants/index'
import { getCasinoProvidersList } from 'redux-thunk/thunk/game.thunk'

const ProvidersListing = (props) => {
  const { homeCasinoSettings } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { providers } = useSelector((state) => state.games)
  const LIMIT = 30

  useEffect(() => {
    if (!providers) {
      dispatch(getCasinoProvidersList({ offset: 0, limit: LIMIT }))
    }
  }, [])

  return (
    <section className='provider-section'>
      <div className='inner-heading  d-block  d-lg-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center mb-3 mb-lg-0'>
          <GamesProviderHeadingIcon className='heading-icon' />
          <h4 className='text-uppercase'>{t('providers')}</h4>
          <a
            href=''
            className='heading-link'
            onClick={(e) => {
              e.preventDefault()
              history.push(ROUTE_PATHS.PROVIDERS)
              dispatch(setViewAllGames(null))
            }}
          >{t('viewAll')}
          </a>
        </div>
      </div>
      <div className='slide-wrap providers-slide p-0 m-0'>
        <CustomSlider {...homeCasinoSettings}>
          {providers?.rows?.map(item =>
            <div
              key={item?.providerId}
              className='col px-06'
              onClick={(e) => {
                e?.preventDefault()
                history.push(`${ROUTE_PATHS.PROVIDERS}/${item?.name}`, { providerId: item?.providerId })
              }}
            >
              <div className='theme-card'>
                <div className='provider-logo'>
                  <GameImg imgSrc={item?.logo ? item?.logo : provider1} />
                </div>
                <div className='provider-badge-wrap'>
                  {item?.gameCount} {t('games')}
                </div>
              </div>
            </div>)}
        </CustomSlider>
      </div>
    </section>
  )
}

export default React.memo(ProvidersListing)
