import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { formatPrice, getPrecision } from 'utils/helperFunctions.utils'
import { clutchWalletIcon, walletBalanceCoins } from 'components/ui-kit/Icons/png/index'
import { CoinsIcon, InfoIcon, NewwalletIcon } from 'components/ui-kit/Icons/svg/index'
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import { TOOLTIP_VARIANT } from 'constants/index'
import './CurrencyDropdown.scss'

const CurrencyDropdown = (props) => {
  const { sidenavExpand } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { selectedWallet } = useSelector((state) => state.user)
  const { showSideNav } = useSelector(state => state.settings)
  const PRECISION_COUNT = 2

  return (
    <div className='search-filter-right d-flex align-items-center currency-dropdown-wrap flex-nowrap'>
      <button
        className='btn btn-primary currencies-btn dropdown-toggle'
        type='button'
        id='currencies_dropdown_button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
        onClick={() => {
          if (width < 768) {
            showSideNav && sidenavExpand()
          }
        }}
      >
        <div className='d-flex main-currency-wrapper'>
          <span className='currency-main'>
            <img src={clutchWalletIcon} />
          </span>
          <div className='currency-text'>
            {`${
              selectedWallet?.currency?.isFiat
                ? selectedWallet?.currency?.symbol
                : ''
            }${selectedWallet ? formatPrice(getPrecision(parseFloat(selectedWallet?.amount), PRECISION_COUNT), false, PRECISION_COUNT) : '$0.00'}`}
          </div>
        </div>
      </button>
      <div
        className='header-wallet-icon d-flex d-xxl-none'
        onClick={() => { dispatch(setShowCashierPopup(true)) }}
      >
        <NewwalletIcon />
      </div>
      <div
        className='dropdown-menu provider-list currencies-options'
        aria-labelledby='currencies_dropdown'
        id='currencies_dropdown'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='balance-head-wrap d-flex justify-content-between align-items-center'>
          <div className='balance-section d-flex justify-content-between align-items-center'>
            <div className='bal-txt text-uppercase'>{t('balances')}</div>
            <button
              className='d-none btn btn-secondary add-btn d-flex justify-content-center align-items-center'
              onClick={() => dispatch(setShowCashierPopup(true))}
            >
              +
            </button>
          </div>
          <div className='total-bal'>
            {t('total')}:
            <span className='amount'>
              {` ${
              selectedWallet?.currency?.isFiat
                ? selectedWallet?.currency?.symbol
                : ''
            }${selectedWallet ? formatPrice(getPrecision(parseFloat(selectedWallet?.amount), PRECISION_COUNT), false, PRECISION_COUNT) : '$0.00'}`}
            </span>
          </div>
        </div>
        <div className='balance-box mb-2'>
          <div className='d-flex align-items-center gap-3'>
            <div className='custom-switch'>
              <input
                type='checkbox'
                name='checkbox'
                checked
                readOnly
              />
            </div>
            <div>
              {t('cashBalance')}
              <div>
                <CoinsIcon />
                <span className='normal-amount ms-2'>  {` ${
              selectedWallet?.currency?.isFiat
                ? selectedWallet?.currency?.symbol
                : ''
            }${selectedWallet ? formatPrice(getPrecision(parseFloat(selectedWallet?.amount), PRECISION_COUNT), false, PRECISION_COUNT) : '$0.00'}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='balance-box mb-2'>
          <div className='d-flex align-items-center gap-3'>
            <CustomTooltip
              variant={TOOLTIP_VARIANT.DEFAULT}
              message='Coming soon'
              showTooltip
              style={{ maxWidth: '280px' }}
            >
              <div className='custom-switch'>
                <input
                  type='checkbox'
                  name='checkbox'
                  checked={false}
                  readOnly
                />
              </div>
            </CustomTooltip>
            <div>
              {t('bonusBalance')}
              <CustomTooltip
                variant={TOOLTIP_VARIANT.DEFAULT}
                message='When selected, bets are placed using your bonus balance before your cash balance. If the bet wins, the winnings are credited to your cash balance.'
                showTooltip
                style={{ maxWidth: '280px' }}
              ><span className='info-icon'><InfoIcon /></span>
              </CustomTooltip>
              <div>
                <CoinsIcon /> <span className='normal-amount ms-2'>${selectedWallet?.nonCashAmount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='balance-box'>
          <div className='d-flex align-items-center gap-3 coins '>
            <img src={walletBalanceCoins} />
            <div>
              Nextgen XP
              <CustomTooltip
                variant={TOOLTIP_VARIANT.DEFAULT}
                message='Coming soon'
                showTooltip
              ><span className='info-icon'><InfoIcon /></span>
              </CustomTooltip>
              <div>
                <span className='normal-amount'>Coming Soon</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrencyDropdown
