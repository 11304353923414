import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AmountInput from '../AmountInput/index'
import { CASHIER_TABS } from 'constants/index'
import { initiateWithdrawRequest } from 'redux-thunk/thunk/payment.thunk'
import { gigadatWithdraw } from 'components/ui-kit/Icons/png/index'

const FiatWithdraw = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleWithdrawRequest = async (values) => {
    dispatch(initiateWithdrawRequest({ amount: values?.amount, transactionType: 'withdraw' }))
  }

  return (
    <div
      className='tab-pane fade'
      id='pills-cashier-fiat-withdraw'
      role='tabpanel'
      aria-labelledby='pills-cashier-fiat-withdraw-tab'
      tabIndex='0'
    >
      <AmountInput
        tab={CASHIER_TABS.FIAT_WITHDRAW}
        buttonText={t('withdraw')}
        title='Interac® e-Transfer'
        handleRequest={handleWithdrawRequest}
        imgUrl={gigadatWithdraw}
        tradeMarkText='®Trade-mark of Interac Corp. Used Under License.'
      />
    </div>
  )
}

export default React.memo(FiatWithdraw)
