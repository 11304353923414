import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import {
  setInviteFriendsStatus,
  setShowCashierPopup,
  setShowSearchPopup,
  setShowSideNav
} from 'redux-thunk/redux/slices/settings.slice'
import { getItem, setItem } from 'helpers/localstorage.helpers'
import {
  CRYPTO_NAME,
  ROUTE_PATHS,
  SHOW_SIDE_NAV,
  TOOLTIP_VARIANT
} from 'constants/index'
import { getCasinoAllCategories } from 'redux-thunk/thunk/game.thunk'
// import { openInfoToaster } from 'helpers/toaster.helpers'
import {
  // NftMenuIcon,
  // PokerMenuIcon,
  RankUpMenuIcon,
  FriendMenuIcon,
  // PromotionMenuIcon,
  // PartnerProgramMenuIcon,
  // LiveSupportMenuIcon,
  // CloseWhiteIcon,
  // OptionLoadingIcon,
  SearchIconIcon
  // PolicyMenuIcon,
  // AboutMenuIcon,
  // RuleBookMenuIcon
} from 'components/ui-kit/Icons/svg/index'
import { setSearchParam } from 'redux-thunk/redux/slices/sportsbook.slice'
import LiveValues from './components/LiveValues/index'
import { getCryptoCoins } from 'redux-thunk/thunk/crypto.thunk'
// import { getAuthToken } from 'helpers/cookie.helpers'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import SportsBookSection from './components/SportsBookSection/index'
import CasinoSection from './components/CasinoSection/index'
import CryptoSection from './components/CryptoSection/index'
import ResponsiveButtonBar from './components/ResponsiveButtonBar/index'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import OriginalsSection from './components/OriginalsSection/index'
import './sideNavBar.scss'

function SideNavbar () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // const token = !!getAuthToken()
  const { width } = useWindowSize()
  const { pathname, search } = useLocation()
  const { showSideNav } = useSelector((state) => state?.settings)
  const history = useHistory()
  const { loginData } = useSelector((state) => state.auth)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [showCasinoOptions, setShowCasinoOptions] = useState(false)
  const [showSportsbookOptions, setShowSportsbookOptions] = useState(false)
  const [showOriginalsOptions, setShowOriginalsOptions] = useState(false)
  const [showCryptoOptions, setShowCryptoOptions] = useState(false)

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const sidenavExpand = useCallback(() => {
    setItem(SHOW_SIDE_NAV, !showSideNav)
    dispatch(setShowSideNav(!showSideNav))
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close')
  }, [showSideNav])

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSideNav(false))
    }
  }, [pathname])

  useEffect(() => {
    if (pathname === ROUTE_PATHS.SPORTS_BOOK) {
      dispatch(setSearchParam(search?.slice(12)))
    }
  }, [search])

  useEffect(() => {
    if (!showSideNav) {
      setShowCasinoOptions(false)
      setShowSportsbookOptions(false)
      setShowCryptoOptions(false)
    }
  }, [showSideNav])

  useEffect(() => {
    if (document?.body) {
      if (showSideNav && width <= 767) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = ''
      }
    }
  }, [showSideNav, width])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (windowWidth <= 768) {
      setItem(SHOW_SIDE_NAV, false)
      dispatch(setShowSideNav(false))
    }
  }, [windowHeight, windowWidth])

  useEffect(() => {
    // if (token) {
    dispatch(getCryptoCoins())
    // }
  }, [])

  useEffect(() => {
    const id = pathname.includes('crypto') && pathname.split('/')[2]
    id && dispatch(setCryptoCoin({ name: CRYPTO_NAME[id], id: id }))
    dispatch(setShowSideNav(getItem(SHOW_SIDE_NAV)))
    dispatch(getCasinoAllCategories())
  }, [])

  return (
    <>
      <div className={`sidebar-wrap ${!showSideNav ? 'sidebar-close' : ''}`}>
        <div className='sidebar-content-wrap w-100'>
          {/* <div className='mob-menu-header d-flex align-items-center justify-content-end '>
            <h3>{t('menu')}</h3>
            <span onClick={sidenavExpand}>
              <CloseWhiteIcon />
            </span>
          </div> */}
          <LiveValues />
          <div className='after-login-sidebar-content d-none d-xxl-none p-3'>
            <div className='d-flex'>
              <button
                className='btn btn-secondary w-100 text-uppercase'
                onClick={() => {
                  if (loginData?.accessToken) {
                    dispatch(setShowCashierPopup(true))
                  } else {
                    dispatch(setShowLoginPopup(true))
                  }
                }}
              >
                {t('cashier')}
              </button>
            </div>
          </div>

          <div className='sidebar-menu-list'>
            <h4 className='sidevar-menu-heading'>{t('mainMenu')}</h4>
            <ul>
              <li>
                {
                  !showSideNav && (
                    <a
                      href=''
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(setShowSearchPopup(true))
                      }}
                    >
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={t('searchGames')}
                        showTooltip
                      >
                        <div className='menu-left d-flex align-items-center'>
                          <div className='menu-icon menu-search-icon'>
                            <SearchIconIcon />
                          </div>
                        </div>
                      </CustomTooltip>
                    </a>
                  )
                }
              </li>
              <OriginalsSection
                showOriginalsOptions={showOriginalsOptions}
                setShowOriginalsOptions={setShowOriginalsOptions}
              />
              <CasinoSection
                showCasinoOptions={showCasinoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
              />
              <SportsBookSection
                setShowSportsbookOptions={setShowSportsbookOptions}
                showSportsbookOptions={showSportsbookOptions}
              />
              <CryptoSection
                setShowCryptoOptions={setShowCryptoOptions}
                showCryptoOptions={showCryptoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
                showCasinoOptions={showCasinoOptions}
              />
              {/* <li>
                <NavLink
                  to={ROUTE_PATHS.US_ELECTIONS}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message='US Elections'
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <RankUpMenuIcon />
                      </div>
                      <p className='m-0'>US Elections</p>
                    </div>
                  </CustomTooltip>
                </NavLink>
              </li> */}
              {/* <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : t('poker')}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: 'Coming Soon!' })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PokerMenuIcon />
                            </div>
                            <p className='m-0'>{t('poker')}</p>
                          </div>
                          <span
                            className='menu-arrow menu-arrow-transparent loading-option'
                          >
                            <OptionLoadingIcon />
                          </span>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: 'Coming Soon!' })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : t('poker')}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PokerMenuIcon />
                            </div>
                            <p className='m-0'>{t('poker')}</p>
                          </div>
                        </CustomTooltip>
                        <span
                          className='menu-arrow menu-arrow-transparent loading-option'
                        >
                          <OptionLoadingIcon />
                        </span>
                      </a>
                      )
                }
              </li> */}
            </ul>
            <h4 className='sidevar-menu-heading sidebar-bottom-title'>
              {t('earnRewards')}
            </h4>
            <ul>
              <li>
                <NavLink
                  to={loginData?.accessToken ? ROUTE_PATHS.REWARDS : ROUTE_PATHS.PRE_LOGIN_REWARDS}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('rankUp')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <RankUpMenuIcon />
                      </div>
                      <p className='m-0'>{t('rankUp')}</p>
                    </div>
                  </CustomTooltip>
                </NavLink>
              </li>
              <li>
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    if (loginData?.accessToken) {
                      dispatch(setShowCashierPopup(true))
                      dispatch(setInviteFriendsStatus(true))
                    } else {
                      history.push(ROUTE_PATHS.PRE_LOGIN_AFFILIATES)
                    }
                  }}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('inviteFriends')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <FriendMenuIcon />
                      </div>
                      <p className='m-0'>{t('inviteFriends')}</p>
                    </div>
                  </CustomTooltip>
                </a>
              </li>
              {/* <li>
                <NavLink
                  to='#'
                  // to={ROUTE_PATHS.PROMOTIONS}
                  activeClassName=''
                  onClick={() => openInfoToaster({ message: 'Coming soon' })}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('accountMenuPromotions')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <PromotionMenuIcon />
                      </div>
                      <p className='m-0'>{t('accountMenuPromotions')}</p>
                    </div>
                  </CustomTooltip>
                </NavLink>
              </li> */}
              {/* <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : t('affiliates')}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: t('comingSoon') })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PartnerProgramMenuIcon />
                            </div>
                            <p className='m-0'>{t('affiliates')}</p>
                          </div>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: t('comingSoon') })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : t('affiliates')}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PartnerProgramMenuIcon />
                            </div>
                            <p className='m-0'>{t('affiliates')}</p>
                          </div>
                        </CustomTooltip>
                      </a>
                      )
                }
              </li> */}
            </ul>
            {/* <h4 className='sidevar-menu-heading sidebar-bottom-title'>
              Others
            </h4>
            <ul>
              <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : 'Live Support'}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: t('comingSoon') })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <LiveSupportMenuIcon />
                            </div>
                            <p className='m-0'>Live Support</p>
                          </div>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: t('comingSoon') })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : 'Live Support'}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <LiveSupportMenuIcon />
                            </div>
                            <p className='m-0'>Live Support</p>
                          </div>
                        </CustomTooltip>
                      </a>
                      )
                }
              </li>
              <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : 'Policies'}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: t('comingSoon') })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PolicyMenuIcon />
                            </div>
                            <p className='m-0'>Policies</p>
                          </div>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: t('comingSoon') })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : 'Policies'}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <PolicyMenuIcon />
                            </div>
                            <p className='m-0'>Policies</p>
                          </div>
                        </CustomTooltip>
                      </a>
                      )
                }
              </li>
              <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : 'About'}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: t('comingSoon') })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <AboutMenuIcon />
                            </div>
                            <p className='m-0'>About</p>
                          </div>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: t('comingSoon') })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : 'About'}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <AboutMenuIcon />
                            </div>
                            <p className='m-0'>About</p>
                          </div>
                        </CustomTooltip>
                      </a>
                      )
                }
              </li>
              <li>
                {
                  showSideNav
                    ? (
                      <CustomTooltip
                        variant={TOOLTIP_VARIANT.SIDE_BAR}
                        message={showSideNav ? t('comingSoon') : 'Rulebook'}
                        showTooltip
                      >
                        <a
                          href=''
                          onClick={(e) => {
                            e.preventDefault()
                            openInfoToaster({ message: t('comingSoon') })
                          }}
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <RuleBookMenuIcon />
                            </div>
                            <p className='m-0'>Rulebook</p>
                          </div>
                        </a>
                      </CustomTooltip>
                      )
                    : (
                      <a
                        href=''
                        onClick={(e) => {
                          e.preventDefault()
                          openInfoToaster({ message: t('comingSoon') })
                        }}
                      >
                        <CustomTooltip
                          variant={TOOLTIP_VARIANT.SIDE_BAR}
                          message={showSideNav ? t('comingSoon') : 'Rulebook'}
                          showTooltip
                        >
                          <div className='menu-left d-flex align-items-center'>
                            <div className='menu-icon'>
                              <RuleBookMenuIcon />
                            </div>
                            <p className='m-0'>Rulebook</p>
                          </div>
                        </CustomTooltip>
                      </a>
                      )
                }
              </li>
            </ul> */}
          </div>
        </div>
      </div>
      <ResponsiveButtonBar sidenavExpand={sidenavExpand} />
    </>
  )
}

export default React.memo(SideNavbar)
