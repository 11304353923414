import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'
import { SuccessMessage } from 'network/messages/successMessages'

export const generatePaymentTokenService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/gigadat/generate-token', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const wallet88PaymentService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/wallet88/bank-transfer', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const wallet88EmtService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, '/wallet88/emt-transfer', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.checkYourMailForFurtherDetails
  })
}
