import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { getItem } from 'helpers/localstorage.helpers'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import { SHOW_SIDE_NAV } from 'constants/index'
import { BrandLogoIcon } from 'components/ui-kit/Icons/svg/index'
import AccordionItem from 'components/ui-kit/AccordionItem/index'
import Platform from './components/Platform/index'
import AboutUs from './components/AboutUs/index'
import Community from './components/Community/index'
import LanguageDropdown from './components/LanguageDropdown/index'
import moment from 'moment'
// import config from 'config/app.config'
import './footer.scss'

// eslint-disable-next-line
const { anj_761fc8a0_9cf7_49cb_b382_3d843413419e } = window

const Footer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { siteInfo } = useSelector(state => state.user)
  const { showSideNav, showChat } = useSelector(
    (state) => state?.settings
  )
  const showSidebarNav = getItem(SHOW_SIDE_NAV)
  const [isCollapseAccordion, setIsCollapseAccordion] = useState({
    accordionOne: false,
    accordionTwo: false,
    accordionThree: false
  })
  const currentYear = moment().year()
  const website = 'www.nextgenbets.com'
  const email = 'info@nextgenbets.com'

  const copyrightDetails = {
    website: 'www.nextgenbets.com',
    email: 'info@nextgenbets.com',
    companyName: 'NextGen Games LTD',
    registrationNumber: '000034735',
    address: '9 Barrack Road Belize City',
    licenseNumber: 'ALSI-202408055-FI3'
  }

  const accordionClickHandler = useCallback((accordionId) => {
    setIsCollapseAccordion((prevState) => ({
      ...prevState,
      [accordionId]: !prevState[accordionId]
    }))
  }, [])

  useEffect(() => {
    if (window.anj_761fc8a0_9cf7_49cb_b382_3d843413419e && typeof window.anj_761fc8a0_9cf7_49cb_b382_3d843413419e.init === 'function') {
      // Call the init function safely
      window.anj_761fc8a0_9cf7_49cb_b382_3d843413419e.init()
    }
    if (!siteInfo) { dispatch(getAllSiteinfo()) }
  }, [])

  return (
    <footer className={`mt-3 footer ${!showSidebarNav ? 'sidebar-close' : ''} ${showSideNav && showChat ? 'footer--sidebars' : ''}`}>
      <div className='footer-top d-none d-sm-block'>
        <div className='content-wrapper custom-spacer d-flex flex-wrap'>
          <div className='footer-content'>
            <div className='footer-about mb-3'>
              <div className='footer-logo'>
                <BrandLogoIcon />
              </div>
              <p>
                <Trans
                  i18nKey='copyrightText'
                  values={copyrightDetails}
                  components={{
                    aLink: <a href={`https://${website}`} target='_blank' rel='noopener noreferrer' />,
                    aMailto: <a href={`mailto:${email}`} />
                  }}
                />
              </p>
            </div>
          </div>
          <div className='footer-menu d-flex justify-content-between flex-wrap'>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('platform')}</h4>
                <Platform />
              </div>
            </div>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('aboutUs')}</h4>
                <AboutUs />
              </div>
            </div>
            <div className='footer-menu-col'>
              <div className='footer-linking d-flex justify-content-between'>
                <h4 className='m-0'>{t('community')}</h4>
                <Community />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-top mob-footer d-block d-sm-none'>
        <div className='col-xl-4 col-lg-4 col-md-12 order-3 order-lg-0'>
          <div className='footer-about mb-3'>
            <div className='footer-logo'>
              <BrandLogoIcon />
            </div>
            <p>
              <Trans
                i18nKey='copyrightText'
                values={copyrightDetails}
                components={{
                  aLink: <a href={`https://${website}`} target='_blank' rel='noopener noreferrer' />,
                  aMailto: <a href={`mailto:${email}`} />
                }}
              />
            </p>
          </div>
        </div>
        <div className='desktop-language d-block d-lg-none'>
          <LanguageDropdown />
        </div>
        <div className='accordion' id='accordionExample'>
          <AccordionItem
            accordionId='accordionOne'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('platform')}
          >
            <Platform />
          </AccordionItem>
          <AccordionItem
            accordionId='accordionTwo'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('aboutUs')}
          >
            <AboutUs />
          </AccordionItem>
          <AccordionItem
            accordionId='accordionThree'
            isCollapseAccordion={isCollapseAccordion}
            accordionClickHandler={accordionClickHandler}
            title={t('community')}
          >
            <Community />
          </AccordionItem>
        </div>
      </div>
      <div className='license-section'>
        <div
          id='anj-761fc8a0-9cf7-49cb-b382-3d843413419e'
          data-anj-seal-id='761fc8a0-9cf7-49cb-b382-3d843413419e'
          data-anj-image-size='87'
          data-anj-image-type='basic-small'
        />
        <p className='age-text'>18+</p>
      </div>
      <div className='desktop-language d-none d-sm-block pt-0'>
        <LanguageDropdown currentYear={currentYear} />
      </div>
      <div
        className='footer-bottom-linking mob-linking d-md-flex justify-content-center align-items-center'
      >
        <p className='m-0'>
          {t('support')}
          <a href='mailto:support@nextgen.app'>support@nextgen.app</a>
        </p>
        <p className='m-0'>
          {t('partners')}
          <a href='mailto:partners@nextgen.app'>partners@nextgen.app</a>
        </p>
        <p className='m-0'>
          {t('legal')}
          <a href='mailto:legal@nextgen.app'>legal@nextgen.app</a>
        </p>
      </div>
      <div
        className='footer-bottom-linking  d-flex d-sm-none justify-content-center align-items-center'
      >
        <p className='mb-0'>© {currentYear} Nextgen.app All rights reserved.</p>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
