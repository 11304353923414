import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { intiEmtWallet88 } from 'redux-thunk/thunk/payment.thunk'
import AmountInput from 'components/ui-kit/AmountInput/index'

const EMT = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleEmtRequest = async (values) => {
    dispatch(intiEmtWallet88({ amount: values?.amount }))
  }

  return (
    <AmountInput
      id='pills-emt'
      title='emt'
      handleRequest={handleEmtRequest}
      btnText={t('initiateEmt')}
    />
  )
}

export default React.memo(EMT)
