import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import LoginTab from './components/Login'
import SignupTab from './components/Signup'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { BrandLogoIcon } from 'components/ui-kit/Icons/svg/index'
// import { AuthBackgroundPng } from 'components/ui-kit/Icons/png/index'
import CommonPopup from 'components/ui-kit/CommonPopup/index'
import './login.scss'
import { loginImg, registerImg } from 'components/ui-kit/Icons/png/index'

const Login = ({ showSignupPopup, setShowVerifyEmailPopup }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isLogin, setIsLogin] = useState(!showSignupPopup)

  const modalCloseHandler = () => {
    dispatch(setShowLoginPopup(false))
    dispatch(setShowSignupPopup(false))
  }

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      id='login_modal'
      modalBodyClasses='p-0'
      modalDialogClass='modal-lg modal-fullscreen-sm-down modal-dialog-scrollable'
      modalContentClass='login-modal'
    >
      <div className='row'>
        <div className='col-lg-6 col-12 mob-login'>
          <div className='auth-form-wrap'>
            <div className='mob-login-header'>
              <div className='auth-logo'>
                <BrandLogoIcon />
              </div>
              <div className='auth-top-btn'>
                <ul className='nav nav-pills mb-0 mb-sm-4' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button onClick={() => setIsLogin(true)} className={`nav-link ${!showSignupPopup && 'active'} text-uppercase`} id='pills-home-tab' data-bs-toggle='pill' data-bs-target='#pills-home' type='button' role='tab' aria-controls='pills-home' aria-selected='true'>{t('login')}</button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button onClick={() => setIsLogin(false)} className={`nav-link ${showSignupPopup && 'active'} text-uppercase`} id='pills-profile-tab' data-bs-toggle='pill' data-bs-target='#pills-profile' type='button' role='tab' aria-controls='pills-profile' aria-selected='false'>{t('register')}</button>
                  </li>
                </ul>
              </div>
            </div>
            <div className='tab-content' id='pills-tabContent'>
              <LoginTab showSignupPopup={showSignupPopup} />
              <SignupTab
                showSignupPopup={showSignupPopup}
                setShowVerifyEmailPopup={setShowVerifyEmailPopup}
              />
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-12 p-0 d-none d-lg-block'>
          <div className='auth-img'>
            <img src={!isLogin ? registerImg : loginImg} alt='auth' />
            <div className='auth-instruction-text'>
              <p>
                {t('usersConsent')}
                <NavLink to='#'>{t('termsOfService')}</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </CommonPopup>
  )
}

export default Login
