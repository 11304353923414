import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AmountInput from '../AmountInput/index'
import { CASHIER_TABS } from 'constants/index'
import { generatePaymentToken } from 'redux-thunk/thunk/payment.thunk'
import { gigadatDeposit } from 'components/ui-kit/Icons/png/index'

const FiatDeposit = () => {
  const { t } = useTranslation()
  const { inviteFriendsStatus } = useSelector(state => state.settings)
  const dispatch = useDispatch()

  const handleDepositRequest = async (values) => {
    dispatch(generatePaymentToken({ amount: values?.amount, transactionType: 'deposit' }))
  }

  return (
    <div
      className={`tab-pane fade ${inviteFriendsStatus ? '' : 'show active'}`}
      id='pills-cashier-fiat-deposit'
      role='tabpanel'
      aria-labelledby='pills-cashier-fiat-deposit-tab'
      tabIndex='0'
    >
      <AmountInput
        tab={CASHIER_TABS.FIAT_DEPOSIT}
        buttonText={t('deposit')}
        title='Interac®'
        handleRequest={handleDepositRequest}
        imgUrl={gigadatDeposit}
        tradeMarkText=''
      />
    </div>
  )
}

export default React.memo(FiatDeposit)
