import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { CASINO_TABS, ROUTE_PATHS, TOOLTIP_VARIANT } from 'constants/index'
import { setShowAllFavGames } from 'redux-thunk/redux/slices/games.slice'
import { setGameCategory, setProvider, setSearchGame, setTab } from 'redux-thunk/redux/slices/lobby.slice'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import {
  HiloIcon,
  CrashGameIcon,
  CoinFlipGameIcon,
  BombIcon,
  PlinkoIcon,
  // OriginalsGamesIcon,
  ClutchOriginalsDIcon
} from 'components/ui-kit/Icons/svg/index'
import { usePreLogin } from 'hooks/usePreLogin'
// import RollerCoasterComp from '../RollerCoasterPrice/index'

const OriginalsSection = (props) => {
  const { showOriginalsOptions, setShowOriginalsOptions } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const { tab } = useSelector((state) => state?.lobby)
  const { showAllFavGames } = useSelector((state) => state.games)
  const { showSideNav } = useSelector((state) => state?.settings)
  const { handleRegister } = usePreLogin()

  const customGamesOptions = useMemo(
    () => [
      {
        label: t('mines'),
        id: uuidv4(),
        path: ROUTE_PATHS.MINE_GAME,
        iconComponent: BombIcon
      },
      {
        label: t('Plinko'),
        id: uuidv4(),
        path: ROUTE_PATHS.PLINKO_GAME,
        iconComponent: PlinkoIcon
      },
      {
        label: t('hilo'),
        id: uuidv4(),
        path: ROUTE_PATHS.HILO_GAME,
        iconComponent: HiloIcon
      },
      {
        label: t('crash'),
        id: uuidv4(),
        path: ROUTE_PATHS.CRASH_GAME,
        iconComponent: CrashGameIcon
      },
      {
        label: t('flipCoin'),
        id: uuidv4(),
        path: ROUTE_PATHS.FLIP_COIN_GAME,
        iconComponent: CoinFlipGameIcon
      }
    ], [])

  useEffect(() => {
    if ((tab === CASINO_TABS.ORIGINALS)) {
      if (!customGamesOptions.some(game => game.path === pathname) && pathname !== ROUTE_PATHS.CASINO) {
        dispatch(setTab(CASINO_TABS.LOBBY))
      }
    }
  }, [pathname])

  return (
    <>
      <li>
        <a
          href='#'
          className={(customGamesOptions.some(game => game.path === pathname) || (tab === CASINO_TABS.ORIGINALS)) ? 'active' : ''}
          onClick={(e) => {
            e.preventDefault()
            history.push(ROUTE_PATHS.CASINO)
            showAllFavGames && dispatch(setShowAllFavGames(false))
            dispatch(setTab(CASINO_TABS.ORIGINALS))
            dispatch(setProvider(''))
            dispatch(setSearchGame(''))
            dispatch(setGameCategory(''))
          }}
        >
          <CustomTooltip
            variant={TOOLTIP_VARIANT.SIDE_BAR}
            message={t('nextgenOriginals')}
            showTooltip={!showSideNav}
          >
            <div className='menu-left d-flex align-items-center'>
              <div className='menu-icon'>
                <ClutchOriginalsDIcon />
              </div>
              <p className='m-0'>{t('nextgenOriginals')}</p>
            </div>
          </CustomTooltip>
          <span
            className='menu-arrow'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowOriginalsOptions((prev) => !prev)
            }}
          >
            <i
              className={`fa fa-caret-${showOriginalsOptions ? 'up' : 'down'}`}
              aria-hidden='true'
            />
          </span>
        </a>
        {showOriginalsOptions && showSideNav && (
          <ul className='submenu-wrap'>
            {customGamesOptions.map((item) => (
              <li key={item.id}>
                <NavLink
                  to={item.path}
                  activeClassName='active'
                  onClick={() => handleRegister({ showLoginPopup: true })}
                >
                  <item.iconComponent />
                  <span>{item.label}</span>
                </NavLink>
              </li>))}
            {/* <RollerCoasterComp disablePrice /> */}
          </ul>
        )}
      </li>
      {showOriginalsOptions && <hr className='theme-divider' />}
    </>
  )
}

export default React.memo(OriginalsSection)
