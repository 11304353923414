import React, { useMemo } from 'react'
import './index.scss'
import CasinoCard from '../CasinoCard'
import CustomSlider from '../CustomSlider/index'

const CasinoCardSlider = (props) => {
  const { items } = props
  const homePromotionsSettings = useMemo(
    () => ({
      initialSlide: 0,
      dots: false,
      infinite: false,
      arrows: true,
      speed: 600,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 3,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }),
    []
  )
  return (
    <div className='casino-card-slider'>
      <CustomSlider {...homePromotionsSettings}>
        {items.map((item, key) => (
          <div key={`item-${key}`} className='casino-card-slider-slide col'>
            <CasinoCard {...item} />
          </div>
        ))}
      </CustomSlider>
    </div>
  )
}

export default React.memo(CasinoCardSlider)
