import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ContentNotFound from 'components/ui-kit/ContentNotFound/index'
import { capitalize } from 'lodash'
import PinnedNotifications from '../PinnedNotifications/index'
import { toUtcTime } from 'utils/helperFunctions.utils'

const NotificationContent = () => {
  const { t } = useTranslation()
  const { announcements } = useSelector(state => state.announcement)
  const topRef = useRef(null)

  const handleScroll = () => {
    topRef.current?.scrollIntoView({ behavior: 'instant' })
  }

  useEffect(() => {
    handleScroll()
  }, [announcements])
  console.log(announcements)
  return (
    <div className='notification-content-scroll'>
      <div id='empty-chat' className='empty-chat' ref={topRef} />
      <PinnedNotifications />
      <div className={`notification-content-wrap ${announcements?.rows?.length === 0 && 'd-flex justify-content-center'}`}>
        {announcements?.rows?.length > 0
          ? announcements?.rows
              ?.filter(el => el.status && !el.isPinned)
              ?.map(element =>
                <div key={element?.id} className='chat-card'>
                  <div className='chat-card-head d-flex'>
                    <div className='d-flex w-100'>
                      <p className='chat-time ms-auto'>{toUtcTime(element.updatedAt, 'MMM Do YY')}</p>
                      {/* <div className='report-user-wrap'>
                <ReportIcoIcon />
              </div> */}
                    </div>
                  </div>
                  <h4 className='chat-text'>
                    {capitalize(element.title)} :
                  </h4>
                  <p>
                    {capitalize(element.description)}
                  </p>
                </div>)
          : (
            <ContentNotFound message={t('noNotificationsFound')} />
            )}
      </div>
    </div>
  )
}

export default React.memo(NotificationContent)
