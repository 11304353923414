import React from 'react'
import { HeadingStarIcon } from '../Icons/svg'
import { bannerSignupBonus } from '../Icons/png/index'
import { useDispatch, useSelector } from 'react-redux'
import { setShowLoginPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import './index.scss'

const SubpageHeader = ({ title, desc, img, mobileImg, variant, children }) => {
  const { loginData } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  return (
    <div className={`subpageHeader ${variant ? `subpageHeader-${variant}` : ''}`}>
      <div className='row gap-3 gap-md-0'>
        <div className='col-12 col-md-6 position-relative'>
          <picture>
            <source media='(max-width: 767px)' srcSet={mobileImg} />
            <source media='(min-width: 768px)' srcSet={mobileImg} />
            <img className='subpageHeader-image' src={mobileImg} alt='' />
          </picture>
          <div className='subpageHeader-content'>
            {title && <h1>{title}<HeadingStarIcon /></h1>}
            {desc && <p>{desc}</p>}
            {children}
          </div>
        </div>

        <div className='col-12 col-md-6'>
          <picture
            className={loginData?.accessToken ? '' : 'cursor-pointer'}
            onClick={() => {
              if (!loginData?.accessToken) {
                dispatch(setShowLoginPopup(true))
              }
            }}
          >
            <source media='(max-width: 767px)' srcSet={bannerSignupBonus} />
            <source media='(min-width: 768px)' srcSet={bannerSignupBonus} />
            <img className='subpageHeader-image' src={bannerSignupBonus} alt='' />
          </picture>
        </div>
      </div>

    </div>
  )
}

export default React.memo(SubpageHeader)
