import i18n from 'i18next'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { loginService, signUpService, logoutService, updatePasswordService, forgetPasswordService, forgetVerifyPasswordService, verifyEmailService, verifyOtpService, updateEmailService, updateEmailForFirstTimeService, loginWithGoogleService, loginWithTwitchService, loginWithPhantomService } from 'network/services/auth.service'
import { signIn, signOut } from 'helpers/cookie.helpers'
import { ROUTE_PATHS } from 'constants/index'
import { fetchUserInformation } from './user.thunk'
import { setUserData } from 'redux-thunk/redux/slices/user.slice'
import { setShowLoginPopup, setShowSignupPopup } from 'redux-thunk/redux/slices/gameSetting.slice'
import { handleLanguageChange, setLoginData } from 'redux-thunk/redux/slices/auth.slice'
import { setShowForgotPasswordPopup, setShowOtpPopup, setShowPasswordUpdatedPopup } from 'redux-thunk/redux/slices/settings.slice'
/**
 * User Login Thunk
 */

export const userLogin = createAsyncThunk('user/login',
  async ({ userNameOrEmail, password, userToken, onLoginSuccess, OnLoginError }, thunkApi) => {
    try {
      const res = await loginService({ userNameOrEmail, password, userToken })
      signIn({
        token: res.accessToken,
        sessionTime: res.sessionTimeLimit || 24
      })
      onLoginSuccess()
      // thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const userSignUp = createAsyncThunk('user/signup',
  async ({ userDetails, setShowVerifyEmailPopup }, thunkApi) => {
    try {
      const res = await signUpService(userDetails)
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      setShowVerifyEmailPopup(true)
      if (Object.keys(res.additionalInfo).length) {
        i18n.changeLanguage(res.additionalInfo.languageCode)
        thunkApi.dispatch(handleLanguageChange(res.additionalInfo.languageCode))
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

/**
 * Operator Logout Thunk
 */
export const userLogout = createAsyncThunk('user/logout',
  async ({ history }, thunkApi) => {
    try {
      const res = await logoutService()
      signOut()
      // if (ethereumClient) {
      //   ethereumClient.disconnect()
      // }
      thunkApi.dispatch(setLoginData(null))
      thunkApi.dispatch(setUserData(null))
      history.push(ROUTE_PATHS.HOME)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

/**
 * Operator Change Password Thunk
 */
export const updatePassword = createAsyncThunk('update/password',
  async ({ oldPassword, newPassword }, thunkApi) => {
    try {
      const res = await updatePasswordService({ oldPassword, newPassword })
      thunkApi?.dispatch(setShowPasswordUpdatedPopup(true))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const forgetPassword = createAsyncThunk('forget/password',
  async ({ userNameOrEmail }, thunkApi) => {
    try {
      const res = await forgetPasswordService({ userNameOrEmail })
      thunkApi?.dispatch(setShowForgotPasswordPopup(false))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const VerifyPasswordToken = createAsyncThunk('verify-password-token',
  async ({ params }, thunkApi) => {
    try {
      const res = await forgetVerifyPasswordService(params)
      thunkApi.dispatch(setShowLoginPopup(true))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const verifyEmail = createAsyncThunk('verify-email',
  async ({ param }, thunkApi) => {
    try {
      const res = await verifyEmailService(param)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const verifyOtp = createAsyncThunk('verify-email-otp',
  async (data, thunkApi) => {
    try {
      const { user } = thunkApi?.getState()?.user
      const res = await verifyOtpService(data)
      thunkApi.dispatch(setUserData({ ...user, email: res?.email }))
      thunkApi.dispatch(setShowOtpPopup(false))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const updateEmail = createAsyncThunk('update/email',
  async ({ newEmail, oldEmail }, thunkApi) => {
    try {
      const res = await updateEmailService({ newEmail, oldEmail })
      thunkApi.dispatch(setShowOtpPopup(true))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const updateEmailForFirstTime = createAsyncThunk('user/update-metamask-email',
  async ({ email }, thunkApi) => {
    try {
      // const { user } = thunkApi?.getState()?.user
      const res = await updateEmailForFirstTimeService({ email })
      thunkApi.dispatch(setShowOtpPopup(true))
      // thunkApi.dispatch(setUserData({ ...user, email: email }))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const loginWithGoogle = createAsyncThunk(
  'auth/login-with-google', async (data, thunkApi) => {
    try {
      const res = await loginWithGoogleService(data)
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const loginWithTwitch = createAsyncThunk(
  'auth/login-with-twitch', async ({ code, redirectToHome }, thunkApi) => {
    try {
      const res = await loginWithTwitchService({ code })
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      redirectToHome()
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })

export const loginWithPhantom = createAsyncThunk(
  'auth/login-with-phantom', async ({ code }, thunkApi) => {
    try {
      const res = await loginWithPhantomService({ code })
      signIn({
        token: res.accessToken
      })
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      thunkApi.dispatch(fetchUserInformation())
      return res
    } catch (error) {
      thunkApi.dispatch(setShowLoginPopup(false))
      thunkApi.dispatch(setShowSignupPopup(false))
      return thunkApi.rejectWithValue(error[0].description)
    }
  })
