import React from 'react'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { blockInvalidChar } from 'utils/helperFunctions.utils'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import './AmountInput.scss'

const AmountInput = (props) => {
  const { id, title, handleRequest, btnText } = props // tabClassName
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const MIN_AMOUNT = 10

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t('amountIsRequired'))
      .required(`${t('amountIsRequired')}`)
      .moreThan(
        MIN_AMOUNT,
        t('minAmount', { min: MIN_AMOUNT })
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  return (
    <div
      className='tab-pane fade'
      id={id}
      role='tabpanel'
      aria-labelledby={`${id}-tab`}
      tabIndex='0'
    >
      <div className='coupon-section referral-section'>
        <img
          src='/assets/images/homePage/coingold.svg'
          alt='Gold'
        />
        <h4 className='text-uppercase'>{title}</h4>
        <div className='modal-inner-card'>
          <div className='change-username'>
            <form onSubmit={handleSubmit(handleRequest)}>
              <div className='form-group text-start'>
                <label htmlFor='newUser' className='form-label'>
                  {startCase(t('amount'))}
                </label>
                <div className='position-relative'>
                  <input
                    type='number'
                    className={`form-control form-copy shadow-none ${errors?.amount ? 'error-input' : ''}`}
                    id='fiatAmount'
                    step='any'
                    onKeyDown={blockInvalidChar}
                    {...register('amount')}
                  />
                  {errors && errors.amount && (
                    <ErrorMessage
                      className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                      message={errors.amount.message}
                    />
                  )}
                  <button
                    type='submit'
                    className='btn btn-secondary'
                    disabled={loading}
                  >
                    <span>
                      {btnText}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AmountInput)
