import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { startCase } from 'lodash'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector, useDispatch } from 'react-redux'
import { blockInvalidChar } from 'utils/helperFunctions.utils'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { clearPaymentCredentials } from 'redux-thunk/redux/slices/payment.slice'
import '../../Referrals/referralSection.scss'
import { setShowCashierPopup } from 'redux-thunk/redux/slices/settings.slice'

const AmountInput = (props) => {
  const { buttonText, title, handleRequest, imgUrl, tradeMarkText } = props
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const { token, transactionId } = useSelector(state => state.payment)
  const dispatch = useDispatch()
  const MIN_WITHDRAW_AMOUNT = 1

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t('amountIsRequired'))
      .required(`${t('amountIsRequired')}`)
      .moreThan(
        MIN_WITHDRAW_AMOUNT,
        t('minWithdrawAmount', { min: MIN_WITHDRAW_AMOUNT })
      )
  })

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  useEffect(() => {
    if (token && transactionId) {
      const link = `https://interac.express-connect.com/cpi?transaction=${transactionId}&token=${token}`
      window.open(link, '_blank')
      dispatch(setShowCashierPopup(false))
    }
    return () => {
      dispatch(clearPaymentCredentials())
    }
  }, [token, transactionId])

  return (
    <div className='coupon-section referral-section'>
      <img
        src={imgUrl}
        alt='Gold'
      />
      <h4 className='text-uppercase'>{title}</h4>
      <div className='modal-inner-card'>
        <div className='change-username'>
          <form onSubmit={handleSubmit(handleRequest)}>
            <div className='form-group text-start'>
              <label htmlFor='newUser' className='form-label'>
                {startCase(t('amount'))}
              </label>
              <div className='position-relative'>
                <input
                  type='number'
                  className={`form-control form-copy shadow-none ${errors?.amount ? 'error-input' : ''}`}
                  id='fiatAmount'
                  step='any'
                  onKeyDown={blockInvalidChar}
                  {...register('amount')}
                />
                {errors && errors.amount && (
                  <ErrorMessage
                    className='color-red text-danger error-msg amount-input  ms-2 d-flex'
                    message={errors.amount.message}
                  />
                )}
                <button
                  type='submit'
                  className='btn btn-secondary'
                  disabled={loading}
                >
                  <span>
                    {buttonText}
                  </span>
                </button>
              </div>
            </div>
          </form>
          {tradeMarkText && <p>{tradeMarkText}</p>}
        </div>
        {/* <a
          className={`${link ? '' : 'd-none'}`}
          href={link}
          target='_blank'
          rel='noreferrer'
        >
          {t('goToPaymentsPage')}
        </a> */}
      </div>
    </div>
  )
}

export default React.memo(AmountInput)
