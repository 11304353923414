import React from 'react'
import { useTranslation } from 'react-i18next'
import { earthIcon } from 'components/ui-kit/Icons/png/index'

const LanguageDropdown = ({ currentYear }) => {
  const { t } = useTranslation()

  return (
    <div className='language-wrap d-flex justify-content-between'>
      <p className='mb-0'>© {currentYear} Nextgen Bets All rights reserved.</p>
      <div className='dropdown language-btn'>
        <button
          className='btn btn-secondary shadow-none' // dropdown-toggle
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <img src={earthIcon} alt='Earth' height='15' width='15' />
          {t('english')}
        </button>
        <ul className='dropdown-menu d-none' aria-labelledby='dropdownMenuButton1'>
          <li>
            <a className='dropdown-item' href='#'>
              {t('hindi')}
            </a>
          </li>
          <li>
            <a className='dropdown-item' href='#'>
              {t('french')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default React.memo(LanguageDropdown)
