import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { bankTransfer } from 'redux-thunk/thunk/payment.thunk'
import AmountInput from 'components/ui-kit/AmountInput/index'

const BankTransfer = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleTransferRequest = async (values) => {
    dispatch(bankTransfer({ amount: values?.amount }))
  }

  return (
    <AmountInput
      id='pills-bank-transfer'
      title={t('bankTransfer')}
      handleRequest={handleTransferRequest}
      btnText={t('initiateTransfer')}
    />
  )
}

export default React.memo(BankTransfer)
