import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'

const Platform = () => {
  const { siteInfo } = useSelector((state) => state.user)
  // const { t } = useTranslation()

  return (
    <ul className='m-0'>
      {siteInfo
        ?.filter((el) => el.cmsSection === 'platform')
        ?.map((el) => (
          <li key={el.id}>
            <NavLink to={`/site-info/${el.slug}`}>
              {el.title}
            </NavLink>
          </li>
        ))}
      {/* <li>
        <NavLink to='#'>{t('support')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('faq')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('partnershipProgram')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('blog')}</NavLink>
      </li>
      <li>
        <NavLink to='#'>{t('helpCenter')}</NavLink>
      </li> */}
    </ul>
  )
}

export default React.memo(Platform)
