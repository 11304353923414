export default {
  404: '404',
  register: 'Register',
  login: 'Login',
  profit: 'Profit',
  payout: 'Target Payout',
  btnDouble: '2×',
  wagered: 'Wagered',
  deposit: 'Deposit',
  inputFieldBtnMax: 'Max',
  bets: 'Bets',
  topHeaderAmount: 'Amount',
  resetPasswordTitle: 'Reset Password',
  resetPasswordNewPasswordTitle: 'New Password',
  resetPasswordNewPasswordErrorsRequired: 'Password is required.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 characters allowed.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximum 16 characters allowed.',
  resetPasswordNewPasswordErrorsPattern: 'Must be 8-16 characters, including uppercase and lowercase letters, numbers, and a special character (e.g., Nextgen@123)',
  resetPasswordConfirmPasswordTitle: 'Confirm Password',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Passwords do not match.',
  resetPasswordResetBtn: 'Reset',
  passwordResetSuccess: 'Password changed',
  resetPasswordWentWrong: 'Something went wrong!',
  partners: 'Partners',
  submit: 'Submit',
  signinUsername: 'Email / Username',
  signinForgetPassword: 'Forgot Password?',
  signinErrorsUserNameRequired: 'Please enter username/email.',
  signinErrorsPasswordRequired: 'Password is required.',
  signinErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signinErrorsPasswordMaxLength: 'Maximum 16 characters allowed.',
  signinTryAgain: 'Something went wrong!, Please try again.',
  signinResetPasswordUsernameErr: 'Please enter username/email to reset password.',
  signinResetMailSentSuccessContent: 'We sent you a reset password link on your email. Please check your email and reset your password.',
  signupTitle: 'Register',
  signupUsername: 'Username',
  signupEmail: 'Email',
  signupWalletConnect: 'Login with Wallet ',
  signupPassword: 'Password',
  signupErrorsUserNameRequired: 'Please enter username.',
  signupErrorsFirstNameRequired: 'Please enter first name.',
  signupErrorsLastNameRequired: 'Please enter last name.',
  signupErrorsUserNameMinLength: 'Minimum {{minimumCharacters}} characters allowed.',
  signupErrorsUserNameMaxLength: 'Maximum {{maximumCharacters}} characters allowed.',
  signupErrorsUserNamePattern: 'Please enter only letters and numbers.',
  signupErrorsStringPattern: 'Please enter only letters.',
  signupErrorsEmailRequired: 'Please enter email.',
  signupErrorsEmailPattern: 'Please enter valid email.',
  signupErrorsPasswordRequired: 'Password is required.',
  signupErrorsPasswordMinLength: 'Minimum 8 characters allowed.',
  signupErrorsPasswordMaxLength: 'Maximum 16 characters allowed.',
  signupErrorsPasswordPattern: 'Must be 8-16 characters, including uppercase and lowercase letters, numbers, and a special character (e.g., Nextgen@123)',
  otpIsRequired: 'OTP is required',
  otpMustBeSixDigits: 'OTP must be 6 digits',
  enterOtp: 'Enter OTP',
  accountMenuTransactions: 'Transactions',
  accountMenuPromotions: 'Promotions',
  accountMenuProfile: 'Profile',
  accountMenuLogout: 'Logout',
  accountMenuWithdraw: 'Withdraw',
  pageNotFound: 'We can’t find the page you are looking for!',
  error: 'Error',
  somethingWentWrong: 'Something went wrong!',
  verifyEmailThankYou: 'Thank You!',
  verifyEmailEmailIsVerified: 'Your email is verified.',
  verifyEmailWentWrong: 'Something went wrong!',
  verifyEmailTokenExpired: 'Email already verified or link expired',
  create: 'Create',
  profilePassword: 'Password',
  profileUploadProfile: 'Upload Photo',
  profileErrorsEmailRequired: 'Email is required.',
  profileErrorsEmailPattern: 'Please enter valid email.',
  profileErrorsEmailMinLength: 'Minimum 3 characters allowed.',
  profileErrorsEmailMaxLength: 'Maximum 20 characters allowed.',
  profileMessagesProfileUpdate: 'Profile updated',
  profileMessagesChangePassword: 'Password updated',
  profileProfilePicProfilePicUpload: 'Upload profile image',
  profileNoNewChanges: 'Couldn’t update profile! No new changes.',
  profileLoggedInWithSocial: 'You have logged in with social login.',
  profileSideNavigatorSound: 'Sound',
  profileSideNavigatorMusic: 'Music',
  profileSideNavigatorProfile: 'My Profile',
  profileSideNavigatorBetHistory: 'Bet History',
  profileSideNavigatorTransactions: 'Transactions',
  profileSideNavigatorDeposit: 'Deposit',
  withdraw: 'Withdraw',
  profileSideNavigatorGameLimits: 'Game Limits',
  profileSideNavigatorReferral: 'Referral',
  profileSideNavigatorChangePassword: 'Change Password',
  profileSideNavigatorTermsConditions: 'Terms & Conditions',
  profileSideNavigatorLogOut: 'Log Out',
  profileSideNavigatorProvablyFair: 'Provably Fair',
  WalletInfoSectionNotAvailable: 'Not Available',
  WalletInfoSectionRealBalance: 'Real Balance',
  GameLimitsMinimumBet: 'Minimum bet',
  GameLimitsMaximumBet: 'Maximum bet',
  GameLimitsMaxWinFor1Bet: 'Maximum win fir one bet',
  ProvablyFairSubTitle: 'This game uses Provably Fair technology to determine game result. This tool gives you ability to change your seed and check fairness of the game.',
  ProvablyFairMaximumBet: 'Maximum bet:',
  ProvablyFairMaxWinFor1Bet: 'Maximum win fir one bet',
  promotionsTitle: 'Promotions',
  promotionsNoLosingData: 'Sorry, No Losing bonus promotions available for ',
  promotionsNoDepositData: 'Sorry, No Deposit bonus promotions available for ',
  promotionsCurrency: ' currency.',
  promotionsViewBtn: 'View',
  promotionsClaimSuccess: 'You have successfully claimed',
  promotionsAvailability: 'Availability: ',
  promotionsAvailabilityTabLosing: 'Losing bonus',
  promotionsAvailabilityTabDeposit: 'Deposit bonus',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. Deposit',
  promotionsDepositBonusTablePercentage: 'Percentage',
  promotionsDepositBonusTableMaxBonus: 'Max Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover multiplier',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Max rollover per bet',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Time to achieve rollover target',
  promotionsBonusCode: 'BONUS CODE: ',
  promotionsLossesClaim: 'Losses can be claimed for(within campaign period): ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Percentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. losing amount',
  promotionsBonusStatus: 'Bonus Status: ',
  promotionsBonusAmount: 'Bonus Amount: ',
  promotionsRolloverTarget: 'Rollover target: ',
  promotionsRolloverAchieved: 'Rollover achieved: ',
  promotionsActivatebtn: 'ACTIVATE',
  promotionsClaimBtn: 'CLAIM',
  promotionsCancelBtn: 'CANCEL',
  promotionsBackBtn: 'BACK',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Terms & Conditions',
  promotionsClaimDepositBonusTitle: 'Steps for claiming deposit bonus',
  promotionsClaimDepositBonusFirst: 'Activate Bonus code',
  promotionsClaimDepositBonusSecond: 'Deposit amount to wallet',
  promotionsClaimDepositBonusThird: 'Achieve rollover target for claiming bonus',
  promotionsClaimLosingBonusTitle: 'Steps for claiming losing bonus',
  promotionsClaimLosingBonusFirst: 'Activate Bonus code',
  promotionsClaimLosingBonusSecond: 'Play your favorite games',
  promotionsClaimLosingBonusThird: 'Click ’Claim losses’ to claim losses within campaign period',
  promotionsWentWrong: 'Something went wrong!',
  transactionTitle: 'Transactions',
  transactionBetId: 'Bet ID',
  transactionUser: 'User',
  transactionPayout: 'Payout',
  transactionAmount: 'Amount',
  transactionTime: 'Date',
  transactionProfit: 'Profit',
  transactionCashout: 'Cashout',
  transactionBet: 'Bet',
  transactionMultix: 'Multi.x',
  transactionWin: 'Win',
  transactionFairness: 'Fairness',
  transactionReferFriend: 'Refer a Friend',
  transactionTotal: 'Total Bets',
  transactionWins: 'Wins',
  transactionRefresh: 'Refresh',
  transactionFilterTopMultipliers: 'Top Multipliers',
  transactionFilterHugeWins: 'Huge Wins',
  transactionFilterBiggestWins: 'Biggest Wins',
  transactionFilterMultipliers: 'Multipliers',
  transactionRealBalance: 'Real Balance',
  depositWithdrawTXDate: 'Date',
  depositWithdrawTXAmount: 'Amount',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'Transaction type',
  depositWithdrawTXStatus: 'Status',
  depositWithdrawTXNoData: 'No data available.',
  depositTitle: 'Deposit',
  depositHistory: 'History',
  depositSubTitle: 'User can not deposit or withdraw fiat currency. Please contact Admin to transact in fiat currency.',
  depositCopyNotSupported: 'Your browser does not support automatic Copy to Clipboard.\nPlease try to copy the code manually.',
  depositCopied: 'Address copied.',
  withdrawNoBalance: 'Not enough balance.',
  withdrawMinAmount: 'Please enter amount greater than or equal to {{min}} .',
  paginationNextBtn: 'Next',
  paginationPrevBtn: 'Previous',
  LoginRequired: 'Login required!',
  betPlacedSuccessfully: 'Bet Placed',
  betCancelledSuccessfully: 'Bet cancelled successfully',
  PlayerEscapedSuccessfully: 'Player exited',
  CopiedSuccessfull: 'Copied successfull',
  usersConsent: 'By accessing this site, I confirm that I am 18 years old, and have reviewed, \n understood and consented to the',
  termsOfService: 'Terms of Service',
  email: 'Email',
  playNow: 'Play Now',
  orContinueWith: 'Or Continue With',
  metamaskExtensionNotAvailable: 'Please install the metaMask extension and refresh the page.',
  cashier: 'CASHIER',
  account: 'ACCOUNT',
  hello: 'Hello',
  balance: 'Balance',
  referrals: 'Referrals',
  settings: 'Settings',
  withdrawals: 'Withdrawals',
  demoWallet: 'Demo Wallet',
  wallet: 'Wallet',
  user: 'User',
  amountIsRequired: 'Amount is required',
  minDepositAmount: 'Deposit amount must be greater than {{min}}.',
  maxDepositAmount: 'Deposit amount must be less than {{max}}.',
  minWithdrawAmount: 'Must be greater than {{min}}',
  withdrawMustLessThanWallet: 'Withdraw amount must be less than your wallet amount',
  accountTypeIsRequired: 'Account type is required.',
  mobileNumberLength: 'Mobile number must be of {{length}} digits',
  phoneIsRequired: 'Phone is required',
  cpfError: 'CPF must be of {{length}} characters',
  cpfRequiredError: 'CPF is required',
  cnpjError: 'CNPJ must be of {{length}} characters',
  mainMenu: 'MAIN MENU',
  casino: 'Casino',
  sports: 'Sports',
  crypto: 'Crypto',
  NextgenXp: 'Nextgen xp',
  playEarn: 'play & earn',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'EARN REWARDS',
  rankUp: 'Rank Up',
  inviteFriends: 'Invite Friends',
  affiliates: 'Affiliates',
  sportsbook: 'Sportsbook',
  menu: 'Menu',
  allBets: 'All bets',
  highRollers: 'High Rollers',
  luckyBets: 'Lucky Bets',
  trade: 'Trade',
  player: 'PLAYER',
  time: 'TIME',
  wager: 'WAGER',
  payoutText: 'PAYOUT',
  bannerHeadOne: 'Experience true innovation with the highest',
  bannerHeadTwo: 'rewards program within the industry.',
  registerNow: 'Register now',
  copy: 'Copy',
  liveWins: 'LIVE WINS',
  monthlyStatus: 'M',
  weeklyStatus: 'W',
  dailyStatus: 'D',
  nextEcosystem: 'nextgen ecosystem',
  allGames: 'All Games',
  allSports: 'All Sports',
  cryptoFutures: 'crypto futures',
  tradeNow: 'Trade Now',
  allTables: 'All Tables',
  nft: 'NFT',
  viewAll: 'View All',
  rewards: 'Rewards',
  profile: 'Profile',
  nextgenProfile: 'NEXTGEN PROFILE',
  totalBets: 'Total Bets',
  totalWagered: 'Total Wagered',
  joinDate: 'Join Date',
  displaySocialLinks: 'Display Linked Social Accounts',
  privateProfile: 'Private Profile',
  rank: 'Rank',
  nextRank: 'Next Rank',
  changeUsername: 'Change username',
  newPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  changeName: 'Change name',
  linkSocialAccount: 'Link social account',
  privateProfileTooltip: "Once your profile is set to private, other users won't be able to see your wagered activity, rewards, or social links",
  socialLinksTooltip: 'Link your social accounts to allow other users to follow you.',
  balances: 'balances',
  createReferralBalance: 'Create referral balance',
  total: 'Total',
  tips: 'Tips',
  receivedAmount: 'Received amount',
  dateOrTime: 'Date/Time',
  view: 'View',
  allChains: 'All Chains',
  creditAmount: 'Credit amount',
  setCustomReferralLink: 'Set custom referrals link',
  changeEmail: 'Change email',
  newEmail: 'New email',
  newUsername: 'New username',
  username: 'Username',
  changePassword: 'Change password',
  twoFactorAuthentication: 'two-factor authentication',
  twoFactorAuthenticationTitle: 'Using Two-Factor Authentication Is Highly Recommended Because It Protects Your Account With Both Your Password And Your Phone. An Email And Password Must Be Set To Activate 2FA. While 2FA Is Enabled, You Will Not Be Able To Login Via Steam.',
  enable2fa: 'Enable 2FA',
  verifyIdentityByKyc: 'Verify your identity (kyc)',
  oldPassword: 'Old password',
  live: 'Live',
  soccer: 'Soccer',
  tennis: 'Tennis',
  fifa: 'Fifa',
  basketball: 'Basketball',
  iceHockey: 'Ice Hokey',
  volleyball: 'Volleyball',
  tableTennis: 'Table Tennis',
  baseball: 'Baseball',
  golf: 'Golf',
  boxing: 'Boxing',
  myBets: 'My Bets',
  favourites: 'Favourites',
  americanFootball: 'American Football',
  buyCrypto: 'Buy Crypto',
  oldEmail: 'Old email',
  loggedInSuccess: 'Login complete',
  loggedOutSuccess: 'Logout complete',
  signup: 'Registration complete',
  tokenVerify: 'Verification token sent to your email. Please verify',
  emailVerify: 'Email verified',
  emailChangedSuccess: 'Email updated',
  emailUpdateOtp: 'OTP sent to new mail',
  profileUpdatedSuccess: 'Profile updated',
  otpVerified: 'One-time password (OTP) verified',
  twoFADeactivated: 'Two-Factor Authentication disabled',
  internalServerError: 'Internal server error',
  unAuthorized: 'Unauthorized',
  dataNotFound: 'Data Not Found',
  setReferralLink: 'Set referral link...',
  notFound: 'Not Found',
  nextgenOriginals: 'Nextgen Originals',
  status: 'Status',
  success: 'Success',
  failed: 'Failed',
  pending: 'Pending',
  date: 'Date',
  to: 'To',
  roundId: 'Round ID',
  bet: 'Bet',
  win: 'Win',
  at: 'at',
  downloadAll: 'Download All',
  gameText: 'Game',
  searchGames: 'Search games',
  provider: 'Provider',
  search: 'Search',
  all: 'All',
  sortBy: 'Sort by',
  popular: 'Popular',
  loadMore: 'Load More',
  bitcoin: 'Bitcoin',
  btc: 'BTC',
  chat: 'Chat',
  loginToChat: 'Login to chat',
  send: 'Send',
  expand: 'Expand',
  collapse: 'Collapse',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'Remove from favourites',
  addToFavourite: 'Add to favourites',
  verify: 'Verify',
  accountVerification: 'Account verification',
  recentlyPlayed: 'Recently Played',
  moreFrom: 'More from',
  demoMode: 'Demo Mode',
  providersList: 'Providers List',
  games: 'Games',
  providers: 'Providers',
  close: 'Close',
  joinedOn: 'Joined on',
  rewarded: 'Rewarded',
  saySomething: 'Say something',
  addressIsRequired: 'Address is required',
  withdrawRequestSent: 'Withdrawal request submitted. Awaiting approval.',
  noMatchesFound: 'No matches found',
  tip: 'TIP',
  sendTip: 'Send tip',
  minAmount: 'Amount must be greater than {{min}}',
  maxAmount: 'Amount must be less than {{max}}',
  doNotShowTip: "Don't show tip in chat",
  demoAvailable: 'Demo available',
  denoNotAvailable: 'Demo not available',
  goFullscreen: 'Go fullscreen',
  endsAt: 'Ends at',
  inProgress: 'In progress',
  eventEnd: 'Event end',
  latestPromotions: 'Latest Promotions',
  archived: 'Archived',
  noPromotionsFound: 'No promotions found',
  claim: 'Claim',
  add: 'Add',
  viewDashboard: 'View Dashboard',
  prize: 'Prize',
  timeLeft: 'Time Left',
  viewTransactions: 'View transactions',
  sendAmountInCashier: 'Send the amount of {{- currency}} of your choice to the following address to receive the equivalent in Coins.',
  yourPersonalDepositAddress: 'Your personal {{- currency}} deposit address',
  copyAddress: 'Copy address',
  theValueOfCurrencyMayChange: 'The value of {{- currency}} may change between now and the time we receive your payment',
  doNotHaveAnyCurrency: "Don't have any {{- currency}}?",
  depositOptions: 'Deposit options',
  withdrawalOptions: 'Withdrawal options',
  withdrawCurrency: 'Withdraw {{- currency}}',
  enterAddressToReceiveFunds: '  Please enter the {{- currency}} wallet address you wish to receive the funds on. Once confirmed, the withdrawal is usually processed within a few minutes.',
  receivingCurrencyAddress: 'Receiving {{- currency}} address',
  withdrawalAmount: 'Withdrawal amount',
  pasteYourCurrencyAddressHere: 'Paste your {{- currency}} wallet address here...',
  request: 'Request',
  receiveTheSpecifiedAmount: '*You will receive the specified {{- currency}} amount to your withdrawal address',
  valueMayVary: '*The value subtracted from your balance may vary between now and the time we process your withdrawal',
  coupons: 'Coupons',
  updateEmail: 'Update email',
  update: 'Update',
  loginHistory: 'Login history',
  showLoginHistory: 'Show login history',
  wagerErr: 'Wager Amount is required',
  minWagerErr: 'Minimum wager amount is 1',
  mulValErr: 'Multiplier value between 1 - 1000',
  closeBetErr: 'Enter valid close bet amount',
  profitErr: 'Enter valid take profit amount',
  multiplierErr: 'Multiplier Amount is required',
  manual: 'Manual',
  auto: 'Auto',
  pnLFee: 'PnL Fee',
  flatFee: 'Flat Fee',
  takeProfitLabel: 'TAKE PROFIT AT PRICE',
  closeBetLabel: 'CLOSE BET AT PRICE',
  priceUpLabel: 'WILL THE PRICE GO UP OR DOWN?',
  up: 'Up',
  down: 'Down',
  payoutMul: 'PAYOUT MULTIPLIER',
  safe: 'Safe',
  wild: 'Wild',
  placeBet: 'Place Bet',
  roundEnd: 'ROUND ENDS IN',
  timerInfo: 'All Rollercoaster bets will be closed and price will reset to $1,000 each day at 00:00 UTC',
  month: 'month',
  PNL: 'PNL',
  ROI: 'ROI',
  roi: 'roi',
  funding: 'Funding/H',
  exitPriceLabel: 'Exit Price',
  cashedOut: 'Cashed Out',
  open: 'Open',
  chartPreferences: 'Chart Preferences',
  yAxis: 'Y-Axis Preferences',
  additionalFeatures: 'Additional Features',
  themes: 'Themes',
  locale: 'Locale',
  changeTimezone: 'Change Timezone',
  changeLanguage: 'Change Language',
  tooltip: 'Tooltip',
  field: 'Field',
  value: 'Value',
  dateTime: 'Date/Time',
  winningAmount: 'winningAmount',
  activeBets: 'Active bets',
  closedBets: 'Closed bets',
  publicBets: 'Public bets',
  leaderboard: 'Leaderboard',
  howToPlay: 'How To Play',
  rOICalculator: 'ROI Calculator',
  allInstruments: 'All Instruments',
  week: 'Week',
  monthLabel: 'Month',
  day: 'Day',
  betLabel: 'BET',
  entryPrice: 'ENTRY PRICE',
  bustPriceLabel: 'Bust Price',
  multiplierLabel: 'MULTIPLIER',
  exitPrice: 'EXIT PRICE',
  pnl: 'P&L',
  details: 'View details',
  profitLoss: 'Profit & Loss',
  noBalance: 'Not enough balance.',
  maxBetAmount: 'Max bet amount',
  limit: 'limit exceeded.',
  minBetAmount: 'Min bet amount',
  rollsLimitMsg: 'Number of rolls must be less than or equal to 100.',
  mines: 'Mines',
  loginFailed: 'Login failed',
  noEntries: 'No entries',
  descriptionIsRequired: 'Description is required',
  description: 'Description',
  report: 'Report',
  userReportedSuccessfully: 'User reported',
  tipSendSuccessfully: 'Tip sent',
  walletSettings: 'Wallet Settings',
  hideZero: 'Hide zero balances',
  hideZeroInfo: "Your zero balance won't appear in your wallet",
  displayFiat: 'Display crypto in fiat',
  displayFiatInfo: 'All bets & transactions will be settled in the crypto equivalent',
  noteForApprox: 'Please note that these are currency approximations.',
  save: 'Save',
  limits: 'Limits',
  wagerLimit: 'Wager Limit',
  lossLimit: 'Loss Limit',
  depositLimit: 'Deposit Limit',
  takeABreak: 'Take A Break',
  customTimePeriod: 'Custom Time Period',
  timePeriod: 'Time Period',
  selfExclusion: 'Self Exclusion',
  confirm: 'Confirm',
  setLimit: 'Set Limit',
  removeLimit: 'Remove Limit',
  enterAmount: 'Enter Amount',
  hours: 'Hours',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  cancel: 'Cancel',
  usedLimit: 'Used Limit',
  type: 'Type',
  updatedAt: 'Updated At',
  editLimit: 'Edit Limit',
  limitRemovedMessage: 'Your Following Limit Will Be Removed.',
  setLimitMessage: 'You Are About To Set The Following Limit To Your Account. Please Note, That In Case You Want To Change The Limit, You Can Do That After 24hrs.',
  setBreakMessage: 'You Are About To Block The Access To Your Account For A Preset Period Of Time. Please Know That Once You Use The Option, You Will Not Be Able To Log In To Your Account To Change The Block Period. If You Would Like To Choose A Different Block Period Or Extend The Existing One, Please Contact Us Via Live Support.',
  setSessionLimitMessage: 'You Are About To Set The Following Limit To Your Account. Please Note, That In Case You Want To Change The Limit, It Will Take 24 Hours For The Limit To Be Changed After You Set Another Limit Amount.',
  removeSessionLimitMessage: 'Your Session Limit Will Be Removed',
  notAchieved: 'not achieved',
  flexibleRegistration: 'Flexible Registration!',
  mostPlayed: 'Most played',
  badge: 'Badges',
  allBadges: 'All Badges',
  socialMedia: 'Social media',
  allLinks: 'All Links',
  statistics: 'Statistics',
  newMessage: 'New message',
  tipFor: 'tip for',
  play: 'Play',
  betOn: 'Bet on',
  ecosystemUnbeatableOdds: 'Bet Better - Access unbeatable odds on exciting events with our top-tier betting platform!',
  startBetting: 'Start Betting',
  ecosystemJoinTheFun: 'Join the Fun - Explore Nextgen’s exclusive games and over +9,000 casino favorites!',
  futures: 'Futures',
  ecosystemTrade: 'Trade Smarter, Maximize your Wins - Experience up to 1,000x leverage!',
  joinNow: 'Join Now',
  noNotificationsFound: 'No notifications found',
  notifications: 'Notifications',
  lobby: 'Lobby',
  rollercoaster: 'Nextgen Coaster',
  applyReferralCode: 'Apply referral code',
  referralCode: 'Referral code',
  referralLink: 'Referral link',
  copyLink: 'Copy link',
  referralLinkCopied: 'Referral link copied',
  withdrawAddressCannotBeSame: 'Withdraw address cannot be same as yours address',
  userId: 'User id',
  active: 'Active',
  inactive: 'Inactive',
  currency: 'Currency',
  hostGames: 'Host games,',
  earnCrypto: 'Earn crypto',
  inviteHostAndEarn: 'Invite, Host & Earn - Run your own Nextgen Events',
  startEarning: 'Start Earning',
  nextGen: 'Nextgen',
  whitePaper: 'Whitepaper',
  deepDiveInto: 'Dive Deep into the Nextgen Coin Economy',
  learnMore: 'Learn More',
  sport: 'sport',
  vipRanked: 'Vip ranked',
  betMoreEarnMore: 'Bet More, Earn More - Maximize your Potential',
  expiresAt: 'Expires on',
  upload: 'Upload',
  pleaseSelectImage: 'Please select image',
  imageSizeMustLessThan: 'Image size must be less than {{size}} mb',
  imageSizeFormat: 'Use png, jpeg, jpg image format.',
  maxFileSize: 'Max. File Size: 2 MB',
  dragPosition: 'Drag to change position',
  onlyNumbers: 'Only Numbers',
  cannotBeRemoved: 'Cannot be removed',
  amountInBetween: 'Amount should be in between daily and monthly(inclusive)',
  amountEqualOrGreater: 'Amount equal or greater than weekly',
  amountEqualOrLess: 'Amount should be equal or less than weekly and monthly',
  cannotBeIncreased: 'Cannot be increased',
  enterPassword: 'Enter password',
  sessionLimit: 'Session Limit',
  timePeriodRequired: 'Time period is required',
  amountGTZero: 'Amount greater than zero',
  mma: 'MMA',
  counterStrike: 'Counter Strike',
  rugbyUnion: 'Rugby Union',
  valorant: 'Valorant',
  cricket: 'Cricket',
  dota: 'DOTA-2',
  rugbyLeague: 'Rugby League',
  hilo: 'Hilo',
  crash: 'Crash',
  cancelBetSuccessfully: 'Bet cancelled',
  start: 'Start',
  invalidBetAmount: 'Bet amount is invalid',
  zeroError: 'Must be more than 0',
  flipCoin: 'Coin Flip',
  customs: 'Customs',
  redeemCode: 'REDEEM COUPON CODE',
  couponText: 'We regularly post these on our',
  copyrightText: '<aLink>{{website}}</aLink> is owned and operated by {{companyName}}. Registration number: {{registrationNumber}}, registered address: {{address}}. Contact us at <aMailto>{{email}}</aMailto>. <aLink>{{website}}</aLink> is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. {{licenseNumber}}. <aLink>{{website}}</aLink> has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.',
  platform: 'Platform',
  support: 'Support',
  faq: 'FAQ',
  partnershipProgram: 'Partnership Program',
  blog: 'Blog',
  helpCenter: 'Help Center',
  aboutUs: 'About us',
  community: 'Community',
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  discord: 'Discord',
  english: 'English',
  hindi: 'Hindi',
  french: 'French',
  legal: 'Legal',
  cryptoTransactions: 'Crypto Transactions',
  sendersName: "Sender's name",
  hideLoginHistory: 'Hide login history',
  loginMethod: 'Login method',
  deviceType: 'Device type',
  ip: 'IP',
  tradingDefinition: 'Crypto trading is not gambling by definition, therefore it is not covered by our gaming license.',
  perks: 'perks',
  connected: 'Connection established',
  twitch: 'Twitch',
  connect: 'Connect',
  setUpGoogleAuthenticator: 'SET UP GOOGLE AUTHENTICATOR',
  twoFACode: '2FA CODE',
  downloadTheGoogleAuthenticator: '1. Download the Google Authenticator app for iPhone or Android',
  saveTheFollowingAccountToken: '2. Save the following Account Token (Key) in a secure place',
  accountTokenKey: 'Account Token (Key)',
  scanQRCodeWithApp: '3. Scan QR Code with the Google Authenticator app',
  enterTheTokenBelow: '4. Enter the Token below',
  copied: 'Copied',
  code: 'Code',
  furtherVerificationRequired: 'Further Verification Required',
  firstName: 'First name',
  lastName: 'Last name',
  dateOfBirth: 'Date of birth',
  year: 'Year',
  addressLine: 'Address line',
  addressLineTwo: 'Address line 2',
  city: 'City',
  province: 'Province',
  postalCode: 'Postal code',
  country: 'Country',
  submitInformation: 'Submit information',
  level: 'Level',
  required: 'Required',
  rejected: 'Rejected',
  completed: 'Completed',
  cancelled: 'Cancelled',
  onHold: 'On Hold',
  kycRequestSubmittedSuccessfully: 'KYC request submitted',
  reRequested: 'Re-requested',
  cashBalance: 'Cash Balance',
  bonusBalance: 'Bonus Balance',
  specialBonus: 'Special Bonus',
  today: 'Today',
  noRewards: 'No Rewards',
  viewPendingRewards: 'View Pending Rewards',
  totalClaimed: 'Total Claimed',
  pendingRewards: 'Pending Rewards',
  amount: 'Amount',
  others: 'Others',
  createdAt: 'Created at',
  horseRacing: 'Horse Racing',
  addToHomeScreen: 'Add Nextgen to Home Screen',
  oneTabAccess: '1-tap access on your phone',
  install: 'Install',
  addToHome: 'Add to home screen',
  inChromeSafari: 'In Chrome/Safari, tap the',
  buttonAndChoose: 'button and choose',
  afterCompletingThisStep: 'After completing this step, access Nextgen Bets with one tap on your phone!',
  settlementId: 'Settlement ID',
  commission: 'Commission',
  totalCommission: 'Total Commission',
  commissionSettlement: 'Commission Settlement',
  startplaying: 'Start playing',
  forgotPassword: 'Forgot password',
  resetPassword: 'Reset password',
  locked: 'Locked',
  unlocked: 'Unlocked',
  yourRank: 'Your rank',
  nextRanks: 'Next ranks',
  allRanks: 'All ranks',
  bonusClaimedSuccessfully: 'Bonus claimed',
  passwordUpdated: 'Password updated!',
  thatsOkayItHappens: "That's okay. It happens. Click on the button below to continue. Email",
  passwordsMustMatch: 'Passwords must match',
  youNearlyThere: 'You nearly there!',
  weSendYouVerificationEmail: 'We sent you a verification email. Please check your email and verify your account.',
  verifyEmailAddress: 'Verify Email Address',
  pleaseNoteThatTheLinkWillExpire: 'Please note that this link will expire in 5 days, if you have not signed up to Nextgen Bets, please ignore this email. Thanks',
  autoBetStops: 'Auto bets stops due to bet amount limit exceed',
  casinoBets: 'Casino bets',
  sportsBet: 'Sports bets',
  cryptoTrades: 'Crypto trades',
  customBets: 'Custom bets',
  nothingFound: 'Nothing found',
  bonus: 'Bonus',
  claimedAt: 'Claimed at',
  available: 'Available',
  previous: 'Previous',
  next: 'Next',
  tooManyRequests: 'Too many requests.',
  limitUpdatedSuccessFully: 'Limit updated',
  youLost: 'You lost',
  cashOutFailed: 'Cash-out failed',
  betAlreadyPlaced: 'Bet already placed',
  betCashedOut: 'Bet cashed out',
  noFavouritesIn: 'No favourites in',
  team: 'Nextgen Team',
  fiatAmount: 'Fiat Amount',
  cryptoAmount: 'Crypto Amount',
  moonpay: 'Moonpay',
  paymentMethod: 'Payment Method',
  transactionId: 'Transaction Id',
  address: 'Address',
  comingSoon: 'Coming Soon!',
  minOneCharacterRequired: 'Minimum one character required',
  verifyOtp: 'Verify OTP',
  noResults: 'No results',
  moreFromBrand: 'More from Nextgen Bets',
  cryptoDeposit: 'Crypto deposit',
  cryptoWithdraw: 'Crypto withdraw',
  fiatDeposit: 'Fiat deposit',
  fiatWithdraw: 'Fiat withdraw',
  goToPaymentsPage: 'Go to payments page.',
  bankTransfer: 'Bank transfer',
  initiateTransfer: 'Initiate transfer',
  emt: 'EMT',
  initiateEmt: 'Initiate EMT',
  checkYourMailForFurtherDetails: 'Check your email for further details',
  gigadet: 'Gigadet',
  wallet88: 'Wallet88'
}
