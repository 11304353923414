import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setShowReportUserPopup } from 'redux-thunk/redux/slices/settings.slice'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorMessage from 'components/layout/ErrorMessage'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import Loader from 'components/ui-kit/Loader/index'
import { reportUser } from 'redux-thunk/thunk/chat.thunk'
import CommonPopup from 'components/ui-kit/CommonPopup/index'

const ReportUserPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { userToReport } = useSelector((state) => state.chat)
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(
    (state) => state.loader
  )

  const modalCloseHandler = () => {
    dispatch(setShowReportUserPopup(false))
  }

  const schema = yup.object().shape({
    description: yup
      .string()
      .required(`${t('descriptionIsRequired')}`)
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const handleCancel = () => {
    dispatch(setShowReportUserPopup(false))
  }

  const handleReportUserSubmit = async (values) => {
    dispatch(reportUser({ ...values, reportedUserId: userToReport.id }))
    dispatch(setShowReportUserPopup(false))
  }

  return (
    <CommonPopup
      modalCloseHandler={modalCloseHandler}
      classes='chat-modal tip-modal'
      id='report-user-modal'
      modalBodyClasses='p-0'
    >
      <div className='pt-4 pb-0 px-4'>
        <h3 className='text-uppercase'>
          {t('report')} <span className='color-red'>{userToReport?.name}</span>
        </h3>
      </div>
      <form onSubmit={handleSubmit(handleReportUserSubmit)}>

        <div className='px-4'>
          <div className='mb-2 form-group'>
            <div className='mb-2'>
              <label
                htmlFor='description'
                className='form-label text-uppercase'
              >
                {t('description')}
              </label>{' '}
              <span className='color-red'>*</span>
              <input
                type='text'
                placeholder={t('description')}
                className={`form-control shadow-none ${errors?.description ? 'error-input' : ''}`}
                id='description'
                name='description'
                {...register('description')}
              />
              {errors && errors.description && (
                <ErrorMessage
                  className='color-red text-danger error-msg login-input  ms-2 d-flex'
                  message={errors.description.message}
                />
              )}
            </div>
          </div>
        </div>
        <div className='modal-footer border-0'>
          <button
            type='button'
            className='btn btn-primary me-2'
            onClick={handleCancel}
          >
            <span className='text-uppercase'>{t('cancel')}</span>
          </button>
          <button
            type='submit'
            className='btn btn-secondary text-uppercase'
            disabled={loading}
          >
            {loading
              ? <Loader variant={LOADER_HANDLER_TYPES.submit} />
              : <span className='text-uppercase'>{t('report')}</span>}
          </button>
        </div>
      </form>
    </CommonPopup>
  )
}

export default React.memo(ReportUserPopup)
