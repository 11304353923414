import { createSlice } from '@reduxjs/toolkit'
import { bankTransfer, generatePaymentToken } from 'redux-thunk/thunk/payment.thunk'

const initialState = {
  token: null,
  transactionId: null,
  wallet88Data: {}
}

const {
  actions: { clearPaymentCredentials },
  reducer
} = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearPaymentCredentials: (state, action) => ({
      ...state,
      token: null,
      transactionId: null
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(generatePaymentToken.fulfilled, (state, action) => {
        return {
          ...state,
          token: action.payload?.result?.token,
          transactionId: action.payload?.result?.data?.transactionId
        }
      })
      .addCase(bankTransfer.fulfilled, (state, action) => {
        return {
          ...state,
          wallet88Data: action.payload
        }
      })
  }
})

export default reducer
export { clearPaymentCredentials }
